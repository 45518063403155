import { httpGet, httpPut, httpPost, httpDelete } from '../modules/backend';
import React, { useState, useEffect, useContext } from 'react';
import { useUser } from './UserProvider';

const HttpContext = React.createContext();

function HttpProvider(props) {
    const { user, getClientCompanyId } = useUser();

    function getUrl(endpoint) {
        var url = `${process.env.REACT_APP_API_URL}/api/${endpoint}`;
        if(endpoint.toLowerCase().indexOf('clientcompanyid') == -1) {
            if (url.indexOf('?') > -1) {
                url = `${url}&clientCompanyId=${getClientCompanyId()}`;
            } else {
                url = `${url}?clientCompanyId=${getClientCompanyId()}`;
            }
        }
        return url;
    }

    const get = async function (url) {
        return httpGet(getUrl(url))
    }

    const put = async function(url, values){
        return httpPut(url, {...values, clientCompanyId : getClientCompanyId()});
    }

    const post = async function(url, values){
        return httpPost(url, {...values, clientCompanyId : getClientCompanyId()});
    }

    const deleteHttp = async function(url, values){
        return httpDelete(url, {...values, clientCompanyId : getClientCompanyId()})
    }

    useEffect(() => {

    }, [user]);

    return <HttpContext.Provider value={{ get: get, put: put, post: post, deleteHttp: deleteHttp }} {...props} />
}

function useHttp() {
    const context = useContext(HttpContext)
    return context;
}

export { HttpProvider, useHttp }