import styles from './PaymentDeductionFormInput.module.css'
import React, {useEffect, useRef} from 'react';

export default function PaymentDeductionFormInput({className, name ,value, ...other}) {
	const inputRef = useRef();

	useEffect(() => {
		if(value==0){
			inputRef.current.value = '';
			inputRef.current.focus();
		}
	}, []);


	return <>
	<input ref={inputRef} className={`${styles.InputText_Input}`} onBlur={() => inputRef.current.value === ''? inputRef.current.value = 0 : null } type="number" name={name} value={value} {...other}/>
	</>
}
