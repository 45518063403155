import styles from './Dashboard.module.css'
import React, {useState, useEffect} from 'react';
import DashboardTabs from '../../Symbols/Dashboard/DashboardTabs';
import DashboardEmployees from '../../Symbols/Dashboard/DashboardEmployees';
import DashboardPayroll from '../../Symbols/Dashboard/DashboardPayroll';
import { useUser } from '../../context/UserProvider';
import DashboardModalChooseCompany from './DashboardModalChooseCompany';
import Modal from '../../Modal'

export default function Dashboard({tab}) {
	const [selectedTab, setSelectedTab] = useState(0);
	const { user, getClientCompanyId } = useUser();

	function onSetSelectedTab(value){
		setSelectedTab(value)
	}

	useEffect(() => {
		if(tab == "payroll"){
			setSelectedTab(1)
		}
	}, []);

	useEffect(() => {

	}, [user]);


	return <div className={`${styles.Content}`}>
			<div>
				{user && (getClientCompanyId() === 'undefined' || tab === "ChooseCompany") &&
				<Modal>
					<DashboardModalChooseCompany/>
				 </Modal>
				}
				<div className={`row full-width-with-padding`}>
					<div className={`columns small-12`}>
						<div className={`${styles.Row} row collapse`}>
							<div className={`columns small-12`}>
								<div className={`${styles.TitleWrapperWrapper} text-center`}>
									<span className={`${styles.TitleWrapperWrapper_Title}`}>
										Dashboard
									</span>
								</div>
							</div>
						</div>
						<DashboardTabs selectedTab={selectedTab} setSelectedTab={onSetSelectedTab}/>
						{user && (getClientCompanyId() !== 'undefined') &&
						<>
							{selectedTab === 0 &&
								<DashboardEmployees/>
							}
							{selectedTab === 1 &&
								<DashboardPayroll/>
							}
						</>
						}
					</div>
				</div>
			</div>
		</div>
}
