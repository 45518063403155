import styles from './PayrollSubmissionConfirmationModal.module.css'
import H2 from '../../TextStyles/H2';
import React, { useState } from 'react';
import Modal from '../../modal/Modal';
import P from "../../TextStyles/P";
import FormInputCheckbox from "../App/Form/FormInputCheckbox";
import ButtonPrimary from "../App/Button/ButtonPrimary";

export default function PayrollSubmissionConfirmationModal({onConfirm, onClose, ...others})
{
	const [confirmed, setConfirmed] = useState(false);

	return <>
		<Modal onClose={onClose} {...others}>
			<div>
				<div className={`${styles.ModalTitle}`}>
					<H2>Payroll submission</H2>
				</div>
				<div>
					<P>You will only be able to submit this payroll once. Please confirm that you have entered the information completely and accurately.</P>
				</div>
				<FormInputCheckbox labelClassName={`${styles.ConfirmationLabel}`} onChange={() => setConfirmed(!confirmed)} value={confirmed} label="I confirm the information entered is correct and complete"/>
				<div className={`${styles.ButtonWrapper}`}>
					<ButtonPrimary onClick={onConfirm} className={`${styles.ButtonPrimary}`} disabled={!confirmed} text={"Submit payroll"} type="submit" name="submitPayroll"/>
				</div>
			</div>
		</Modal>
	</>
}
