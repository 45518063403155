import styles from './DashboardEmployees.module.css'
import ButtonGhost from '../App/Button/ButtonGhost';
import FiveColumnRow from '../App/Grid/FiveColumnRow';
import SearchInput from '../App/Search/SeachInput';
import { useUser } from '../../context/UserProvider';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from '../App/Scroll/InfiniteScroll';
import { navigate, Router } from '@reach/router';
import FilterButton from '../App/Button/FilterButton';
import FormInputCheckboxRight from '../App/Form/FormInputCheckboxRightAligned';
import { useHttp } from '../../context/HttpProvider';

export default function DashboardEmployees() {
	const { user, getClientCompanyId } = useUser();
	const [employees, setEmployees] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchString, setSearchString] = useState("");
	const [error, setIsError] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [ascending, setAscending] = useState(true);
	const [sortBy, setSortBy] = useState("Name");
	const [includeLeftEmployees, setIncludeLeftEmployees] = useState(false);
	const { get } = useHttp();

	async function getEmployees() {
		setLoading(true);
		await get(getEmployeeSearchUrl(getClientCompanyId(), searchString, employees.length, ascending, sortBy, includeLeftEmployees))
			.then(async function (result) {
				setEmployees([...employees, ...result.employees])
				if (result.employees.length === 0) {
					setHasMore(false);
				}
				setLoading(false);
			}).catch(function () {
				setIsError(true);
				setLoading(false);
			});
	}

	function getEmployeeSearchUrl(clientCompanyId, searchTerm, skip, ascending, sortBy, includeLeftEmployees) {
		return `employees/by-company-id?ClientCompanyId=${clientCompanyId}&
				SearchTerm=${searchTerm}&
				Skip=${skip}&
				Take=15&
				ascending=${ascending}&
				sortBy=${sortBy}&
				includeLeft=${includeLeftEmployees}`
	}

	async function searchEmployees(searchTerm) {
		setLoading(true);
		var result = await get(getEmployeeSearchUrl(getClientCompanyId(), searchTerm, 0, ascending, sortBy, includeLeftEmployees));
		setEmployees(result.employees);
		setLoading(false);
	}

	useEffect(() => {
		getEmployees("", 0);

	}, []);

	function search(searchString) {
		setSearchString(searchString);
		searchEmployees(searchString);
	}

	function clearSearch() {
		setSearchString("");
		searchEmployees("");
		setHasMore(true);
	}

	function goToEmployeeDetails(id) {
		navigate(`/employeeDetails/${id}/`);
	}

	async function onFilterBy(ascending, type) {
		if(type == "Works Number"){
			type = "PayrollNo";
		}
		setLoading(true);
		var result = await get(getEmployeeSearchUrl(getClientCompanyId(), searchString, 0, ascending, type, includeLeftEmployees));
		setEmployees(result.employees);
		setHasMore(true);
		setLoading(false);

		setAscending(ascending)
		setSortBy(type)
	}

	async function toggleIncludeLeftEmployees() {
		var result = await get(getEmployeeSearchUrl(getClientCompanyId(), searchString, 0, true, "Name", !includeLeftEmployees));
		setEmployees(result.employees);

		setIncludeLeftEmployees(!includeLeftEmployees);
		setSortBy("Name")
		setAscending(true)
	}


	return <>
		<div className={`${styles.Row} row collapse`}>
			<div className={`${styles.SearchWrapper}`}>
				<SearchInput className={`${styles.SearchComponent}`} label="Search" name="search" search={search} clearSearch={clearSearch} />
				<FormInputCheckboxRight className={`${styles.LeftEmployeeCheckbox}`} label="Show left employees" checked={includeLeftEmployees} onChange={() => toggleIncludeLeftEmployees()} />
				<ButtonGhost className={`${styles.ButtonPrimary}`} text="Add employee" name="add-employee" onClick={() => navigate('/add-employee')} />
			</div>
		</div>
		<div className={`row`}>
			<div className={`columns small-12`}>
				<div className={`${styles.SearchResultsHeader} row collapse`}>
					<div className={`columns small-6 medium-3`}>
						<div className={`${styles.NameWrapperWrapper}`}>
							<FilterButton text="Name" onFilter={onFilterBy} />
						</div>
					</div>
					<div className={`columns small-6 medium-2`}>
						<div className={`${styles.PayrollNoWrapperWrapper}`}>
							<FilterButton text="Works Number" onFilter={onFilterBy} />
						</div>
					</div>
					<div className={`columns small-0 medium-3`}>
						<div className={`${styles.DepartmentWrapperWrapper}`}>
							<FilterButton text="Department" onFilter={onFilterBy} />
						</div>
					</div>
					<div className={`columns small-0 medium-2`}>
						<div className={`${styles.NiNumberWrapperWrapper}`}>
							<span className={`${styles.NiNumberWrapperWrapper_NiNumber}`}>NI number</span>
						</div>
					</div>
					<div className={`columns small-0 medium-2`}>
						<div className={`${styles.StatusWrapperWrapper}`}>
							<FilterButton text="Status" onFilter={onFilterBy} />
						</div>
					</div>
				</div>
				<div id="employees-wrapper">
					{employees &&
						employees.map((employee, i) =>
							<FiveColumnRow key={i} className={`${styles.SearchResults}`} employee={employee} onClick={() => goToEmployeeDetails(employee.id)} />
						)
					}
				</div>
				<InfiniteScroll loadFunction={getEmployees} error={error} isLoading={loading} hasMore={hasMore} />
			</div>
		</div>
	</>
}
