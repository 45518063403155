import styles from './FormInputText.module.css'
import React, { useState } from 'react';
import InfoToolTip from './InfoToolTip';
import ValidationIcons from './ValidationIcons'

export default function FormInputText({className, label, name, required,toolTipText,validateRule, ...other}) {
	const [valid, setValid] = useState();

	function validate(e){
		if(validateRule){
			setValid(validateRule(e.target.value))
		}
	}


	return <div className={`${className ? className : ''}`}>
		<label className={`${styles.InputText_Label}`} htmlFor={name}>
			{required ? `${label} *` : label}
		</label>
		<div className={`${styles.InputText_Wrapper}`}>
			<input className={`${styles.InputText_Input}`} type="text" name={name} required={required} onBlur={validate} {...other}/>
			{toolTipText && toolTipText !="" &&
				<div className={`${styles.InputText_ToolTip_Wrapper}`}>
					<InfoToolTip className={`${styles.InputText_ToolTip}`}  text={toolTipText} valid={valid}/>	
				</div>
			}
			{toolTipText === undefined && validateRule &&
				<div className={`${styles.InputText_Validation_Wrapper}`}>
					<div className={`${styles.InputText_Validation_Inner_Wrapper}`}>
						<ValidationIcons valid={valid}/>
					</div>
				</div>
			}
		</div>
	</div>
}
