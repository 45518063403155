import styles from './FileUpload.module.css'
import React, { useEffect, useState } from 'react';
import Paperclip from '../../../Images/Paperclip.svg';
import IconDocument from '../Icon/IconDocument';

export default function FileUpload({className, label, name, required, value, multiple,onRemoveFile, ...other }) {

    const [files, setFiles] = useState([]);

    useEffect(() => {
        setFiles(value);
    }, [value]);

    function onOpenFile(url){
        window.open(url, "_blank")
    }

    return <div className={`${styles.FileUpload} ${className ? className : ''}`}>
        <div className={`${styles.FileUpload_Wrapper}`}>
            <div className={`${styles.FileUpload_Input_Wrapper}`}>
                <input className={`${styles.FileUpload_Input}`} type="file" name={name} id={name} multiple={multiple} required={required} {...other} />

                <label htmlFor={name} className={`${styles.FileUpload_Label}`}>
                    {required ? `${label} *` : label }
                </label>
                <span className={`${styles.FileUpload_Error}`}></span>
            </div>
        </div>
    </div>
}