import styles from './DatePicker.module.css'
import React, { useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'moment/locale/en-gb';
import moment from 'moment';
import InfoToolTip from '../Form/InfoToolTip';
import ValidationIcons from '../Form/ValidationIcons'

export default function DatePicker({ className, label, name, required, onChange,toolTipText,validateRule,disabled, ...props }) {
    const [valid, setValid] = useState();

	function validate(day){
		if(validateRule){
			setValid(validateRule(day))
		}
	}

    if (props.value) {
        props.value = moment(props.value).format("DD/MM/YYYY");
    }

    async function UpdateValue(day) {
        //the time is set to 12:00 this is due to when typing a date the time is set to 00:00
        //then when saving to server if you are in british summer time +1hr
        //it saves as the previous day 23:00
        if(day!=undefined){
            day.setHours(12);
        }
        validate(day)
        onChange(day);
    }

    return <div className={`${styles.Date_Picker} ${className ? className : ""}`}>
        <label htmlFor={name} className={`${styles.Label_label}`}>
            {required ? `${label} *` : label}
        </label>

        <DayPickerInput 
            value={props.value}
            placeholder="dd/mm/yyyy" 
            format="DD/MM/YYYY" 
            formatDate={formatDate} 
            parseDate={parseDate} 
            onDayChange={day => UpdateValue(day)} 
            inputProps= {{name: name, className: `${styles.Label_input}`, required: required, "data-cy": props.dataCy, disabled: disabled}}
            style={ {width: "100%" }}
            />
            {toolTipText && toolTipText !="" &&
				<div className={`${styles.Validation_Wrapper}`}>
					<InfoToolTip className={`${styles.InputText_ToolTip}`}  text={toolTipText} valid={valid}/>	
				</div>
			}
            {toolTipText === undefined && validateRule &&
				<div className={`${styles.Validation_Wrapper}`}>
					<div className={`${styles.Validation_Inner_Wrapper}`}>
						<ValidationIcons valid={valid}/>
					</div>
				</div>
			}
    </div>
}
