import styles from './EditEmployee.module.css'
import React, { useState, useEffect } from 'react';
import useForm from '../../hooks/useForm';
import ButtonPrimary from '../../Symbols/App/Button/ButtonPrimary';
import FormInputText from '../../Symbols/App/Form/FormInputText';
import FormSelectWithoutLabel from '../../Symbols/App/Form/FormSelectWithoutLabel';
import FormSelect from '../../Symbols/App/Form/FormSelect';
import FormInputCheckbox from '../../Symbols/App/Form/FormInputCheckbox';
import { NiCategories, Sex, MaritalStatus, Form, EmployeeFlags } from '../../data/SelectData';
import DatePicker from '../../Symbols/App/DatePicker/DatePicker';
import { Router,navigate } from '@reach/router';
import { useUser } from '../../context/UserProvider';
import {
    ValidateNiNumber,
    NotEmptyString,
    ValidateDateOfBirth,
    ValidatePostcode,
    ValidateEmail,
    ValidateInt,
    ValidateTaxCode,
    ValidateNotNullLengthLessThanOrEqual30,
    ValidateLengthLessThanOrEqual30,
    ValidateNotNullLengthLessThanOrEqual10,
    ValidateLengthLessThanOrEqual2
} from '../../modules/Validation';
import moment from 'moment';
import WorkingDays from "../../Symbols/App/Working Days/WorkingDays";
import FileUpload from "../../Symbols/App/Files/FileUpload";
import Document from "../../Symbols/App/Files/Document";
import fileListToBase64 from "../../helpers/FileHelper";
import LoadingModal from "../../Symbols/App/Loading/LoadingModal";
import ReinstateEmployeeWarningModal from './ReinstateEmployeeWarningModal';
import { useHttp } from '../../context/HttpProvider';

export default function EditEmployee({ employeeId }) {
    const { user } = useUser();
    const [error, setError] = useState();
    const { values, handleChange, handleSubmit, handleCheckboxChange, setInitialValues } = useForm(onFormSubmit);
    const [dateOfBirth, setDateOfBirth] = useState();
    const [startDate, setStartDate] = useState();
    const [employee, setEmployee] = useState({});
    const [flag, setFlag] = useState(null);
    const [leaverToSelectedFlag, setLeaverToSelectedFlag] = useState(null);
    const [endDateDisabled, setEndDateDisabled] = useState(true);
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [addressLine3, setAddressLine3] = useState('');
    const [addressLine4, setAddressLine4] = useState('');
    const [addressLine5, setAddressLine5] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [paymentDates, setPaymentDates] = useState();
    const [departments, setDepartments] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [onHold, setOnHold] = useState(false);
    const leaverValue = 3;
    const { get, put } = useHttp();

    async function getCompanyPaymentScheldules(){
		await get(`client-companies/schedule-dates-by-company-id`)
		.then(async function (result) {
            var pOptions = []
            if(result.scheduleDates){
                result.scheduleDates.forEach(element => {
                    pOptions.push({ text: element.displayText, value: element.id })
                });
                setPaymentDates(pOptions)
            }
			
		}).catch(function (error) {
		});
    }


    useEffect(() => {
        async function getEmployee() {
            const result = await get(`employees/${employeeId}`);
            var employee = result.employee;
            setEmployee(employee);
            setAddressLine1(employee.address.addressLine1);
            setAddressLine2(employee.address.addressLine2);
            setAddressLine3(employee.address.addressLine3);
            setAddressLine4(employee.address.addressLine4);
            setAddressLine5(employee.address.addressLine5);
            setPostalCode(employee.address.postalCode);
            setDateOfBirth(employee.dateOfBirth);
            setStartDate(employee.startDate);
            setDocuments(employee.documents);
            setInitialValues(employee);
            setFlag(employee.flag);
            setOnHold(employee.onHold)
        }
        async function getDepartments(){
            const result = await get(`departments`)
            setDepartments(result.items);
        }

        getEmployee();
        getCompanyPaymentScheldules();
        getDepartments()
    }, [employeeId])

    async function onFormSubmit() {
        values.dateOfBirth = dateOfBirth;
        values.startDate = employee.startDate;
        values.endDate = employee.endDate;
        values.isReinstated = employee.isReinstated;
        values.address.addressLine1 = addressLine1;
        values.address.addressLine2 = addressLine2;
        values.address.addressLine3 = addressLine3;
        values.address.addressLine4 = addressLine4;
        values.address.addressLine5 = addressLine5;
        values.address.postalCode = postalCode;
        values.documents = documents;
        values.flag = flag;

        if(validate(values)){
            setShowLoadingModal(true);
            await put('employees', values)
                .then(async function (response) {
                    setShowLoadingModal(false);
                    navigate('/')
                })
                .catch(function (error) {
                    setShowLoadingModal(false);
                    if(error.response.data.length>0){
                        setError(error.response.data);
                    }else{
                        setError(["Could not add Employee."]);
                    }
                });
         };
    }

    function validate(values){
        setError();
        var errorList = document.getElementsByClassName("Validation_Failed");
        if(errorList.length>0){
            setError("Please fix any errors in the form.");
            return false;
        }else{
            return true;
        } 
    }

    async function handleDateOfBirthChange(date) {
        if (date !== undefined) {
            setDateOfBirth(date);
            setNiCategoryBasedOnDateOfBirth(date)

        }
    }

    async function handleStartDateChange(date) {
        if (date !== undefined) {
            setEmployee({ ...employee, startDate: date })
        }
    }

    async function handleEndDateChange(date) {
        setEmployee({ ...employee, endDate: date })
    }

    async function handlePostCodeChange(event) {
        if (event.target.value.length <= 8) {
            setPostalCode(event.target.value);
        }
    }

    async function handleNINumberChange(event) {
        if (event.target.value.length <= 13) {
            handleChange(event);
        }
    }

    async function handleAddressChange(event) {
        switch (event.target.name) {
            case 'addressLine1':
                setAddressLine1(event.target.value);
                break;
            case 'addressLine2':
                setAddressLine2(event.target.value);
                break;
            case 'addressLine3':
                setAddressLine3(event.target.value);
                break;
            case 'addressLine4':
                setAddressLine4(event.target.value);
                break;
            case 'addressLine5':
                setAddressLine5(event.target.value);
                break;
            default:

        }
    }

    function setNiCategoryBasedOnDateOfBirth(date){
        var YearsAgo21 = new Date();
        YearsAgo21.setFullYear( YearsAgo21.getFullYear() - 21 );

        var YearsAgo65 = new Date();
        YearsAgo65.setFullYear( YearsAgo65.getFullYear() - 65 );
        
        
        //>21 go into A
        if(moment(date).isBefore(YearsAgo21)){
            values.niCategory = "A";
        }
        //<21 go into M 
        if(!moment(date).isBefore(YearsAgo21)){
            values.niCategory = "M";
        }

        //>65 go into B
        if(moment(date).isBefore(YearsAgo65)){
            values.niCategory = "B";
        }
    }

    function handleFileChange(event){
        event.persist();

        async function processFileUpload() {
            if (event.target.files) {
                let files = event.target.files;
                return await fileListToBase64(files);
            }
        }
        processFileUpload().then(async function (value) {
            setDocuments([...documents, ...value]);
        });
    };

    function handleRemoveFile(file) {
        var elements = [...documents];
        var filteredItems = elements.filter(function(item) {
            return item !== file
        })
        setDocuments(filteredItems);
    }

    function onFromLeaverFlagChangeConfirmed() {
        if (leaverToSelectedFlag != leaverValue) {
            setEmployee({ ...employee, endDate: null, isReinstated: true })
            setFlag(leaverToSelectedFlag);
        }
    }

    function handleFlagChange(event) {
        if (flag == leaverValue) {
            setLeaverToSelectedFlag(event.target.value);
            navigate(`/edit-employee/${employeeId}/reinstate-employee-warning`)
        } else {
            setFlag(event.target.value);
        }
    }

    useEffect(() => {
        if (flag == leaverValue){
            setEndDateDisabled(false);
        } else {
            setEndDateDisabled(true);
        }
    },[flag])

    return <div className={`${styles.Content}`}>
        {showLoadingModal &&
            <LoadingModal />
        }

        <Router>
            <ReinstateEmployeeWarningModal 
                path='/reinstate-employee-warning' 
                employeeId={employeeId} 
                onConfirm={onFromLeaverFlagChangeConfirmed}/>
        </Router>

        <div className={`row`}>
            <div className={`columns large-8 large-offset-2 medium-10 medium-offset-1`}>
                <div className={`${styles.HeaderTitleWrapper}`}>
                    <div className={`${styles.TitleWrapperWrapper}`}>
                        <span className={`${styles.TitleWrapperWrapper_Title}`}>Edit Employee</span>
                    </div>
                </div>
                <form className={`${styles.FormWrapper}`} onSubmit={handleSubmit}>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Title" name="title" onChange={handleChange} value={values.title || ''} required toolTipText={"Must be 10 characters or less in length"} validateRule={ValidateNotNullLengthLessThanOrEqual10}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="First name" name="firstName" onChange={handleChange} value={values.firstName || ''} required toolTipText={"Must be 30 characters or less in length"} validateRule={ValidateNotNullLengthLessThanOrEqual30}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Last name" name="lastName" onChange={handleChange} value={values.lastName || ''} required toolTipText={"Must be 30 characters or less in length"} validateRule={ValidateNotNullLengthLessThanOrEqual30}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Initials" name="initials" onChange={handleChange} value={values.initials || ''} toolTipText={"Must be 2 characters or less in length"} validateRule={ValidateLengthLessThanOrEqual2}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Address Line 1" name="addressLine1" onChange={handleAddressChange} value={addressLine1 || ''} required toolTipText={"Must be 30 characters or less in length"} validateRule={ValidateNotNullLengthLessThanOrEqual30}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Address Line 2" name="addressLine2" onChange={handleAddressChange} value={addressLine2 || ''} toolTipText={"Must be 30 characters or less in length"} validateRule={ValidateLengthLessThanOrEqual30}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Address Line 3" name="addressLine3" onChange={handleAddressChange} value={addressLine3 || ''} toolTipText={"Must be 30 characters or less in length"} validateRule={ValidateLengthLessThanOrEqual30} />
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Address Line 4" name="addressLine4" onChange={handleAddressChange} value={addressLine4 || ''} toolTipText={"Must be 30 characters or less in length"} validateRule={ValidateLengthLessThanOrEqual30}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Address Line 5" name="addressLine5" onChange={handleAddressChange} value={addressLine5 || ''} toolTipText={"Must be 30 characters or less in length"} validateRule={ValidateLengthLessThanOrEqual30}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Post Code" name="postCode" onChange={handlePostCodeChange} value={postalCode || ''} required validateRule={ValidatePostcode}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Email Address" name="emailAddress" onChange={handleChange} value={values.emailAddress || ''} required toolTipText={"Must be a valid email address"} validateRule={ValidateEmail}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <DatePicker label="Date of Birth" name="dateOfBirth" onChange={handleDateOfBirthChange} value={dateOfBirth || ''} required toolTipText={"Must be 16 or older"} validateRule={ValidateDateOfBirth}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <DatePicker label="Start Date" name="startDate" onChange={handleStartDateChange} value={employee.startDate || ''} required />
                    </div>
                    <div className={`${styles.SelectInputWrapper}`}>
                        <FormSelectWithoutLabel label="Flag as" name="flag" onChange={handleFlagChange} options={EmployeeFlags} value={flag} required />
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <DatePicker label="End Date" name="endDate" onChange={handleEndDateChange} value={employee.endDate || ''} disabled={endDateDisabled} />
                    </div>
                    <div className={`${styles.InputCheckbox }`}>
                        <FormInputCheckbox label="On Hold" name="onHold" onChange={handleCheckboxChange} value={values.onHold} checked = {values.onHold}/>
                    </div>
                    <div className={`${styles.SelectInputWrapper}`}>
                        <FormSelectWithoutLabel label="NI Category" name="niCategory" onChange={handleChange} options={NiCategories} value={values.niCategory || ''} required disabled/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="NI Number" name="niNumber" onChange={handleNINumberChange} value={values.niNumber || ''} required toolTipText={'NI number correct format LLNNNNNNL'} validateRule={ValidateNiNumber}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Works Number" name="worksNumber" onChange={handleChange} value={values.worksNumber || ''}/>
                    </div>
                    <div className={`${styles.SelectInputWrapper}`}>
                        <FormSelectWithoutLabel label="Sex" name="gender" onChange={handleChange} options={Sex} value={values.gender} required />
                    </div>
                    <div className={`${styles.SelectInputWrapper}`}>
                        <FormSelectWithoutLabel label="Marital Status" name="maritalStatus" onChange={handleChange} options={MaritalStatus} value={values.maritalStatus} required />
                    </div>
                    <div className={`${styles.InputCheckbox }`}>
                        <FormInputCheckbox label="Enable Online Services" checked disabled />
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Have you conducted a Right To Work check?" value={"I'm recording this elsewhere"} required disabled />
                    </div>
                    <div className={`${styles.SelectInputWrapper}`}>
                        <FormSelectWithoutLabel label="Which form has been provided?" name="form" onChange={handleChange} options={Form} value={values.form} required />
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Tax Code" name="taxCode" onChange={handleChange} value={values.taxCode || ''} required validateRule={ValidateTaxCode}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Payment Method" name="paymentMethod" onChange={handleChange} value={'BACS'} required disabled />
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Gross Salary For Year" name="grossSalaryForYear" onChange={handleChange} value={values.grossSalaryForYear || 0} required />
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <WorkingDays name="workingDays" onSelect={(value) => values.workingDaysPattern = value} pattern={values.workingDaysPattern}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Hours Worked In The Week" required={true} name="hoursWorkedInWeek" onChange={handleChange} value={values.hoursWorkedInWeek || 0} />
                    </div>
                    <div className={`${styles.SelectInputWrapper}`}>
                        <FormSelectWithoutLabel label="Department" name="departmentId" onChange={handleChange} options={departments} value={values.departmentId || ''} placeholder={"Please select"}/>
                    </div>
                    <div className={`${styles.SelectInputWrapper}`}>
                        <FormSelectWithoutLabel label="Payment date" name="scheduleDateId" onChange={handleChange} placeholder={""} options={paymentDates} value={values.scheduleDateId || ''} required disabled={employee.hasPayrollSubmitted}/>
                    </div>

                    <FileUpload className={`${styles.ButtonTertiary}`} name="documents" label="Add documents" onChange={handleFileChange} onRemoveFile={handleRemoveFile} value={documents} multiple/>

                    {documents && documents.length>0 &&
                    <div className={`${styles.Row} ${styles.DocumentRow} row`}>
                        <div className={`columns small-12 ${styles.Footer}`}>
                            <div className={`${styles.FooterColumnFullWidth}`}>
                                {documents &&
                                documents.map((document, i) =>
                                    <Document key={documents.indexOf(document)} document={document} onDelete={handleRemoveFile}/>
                                )
                                }
                            </div>
                        </div>
                    </div>
                    }

                    {error &&
                        error.map((text, i) =>
                        <div key={i} className={`${styles.ErrorWrapper}`}>
                            <span className={`${styles.Error}`}>{text}</span>
                        </div>
                        )  
                    }


                    <div className={`${styles.ButtonWrapper}`}>
                        <ButtonPrimary className={`${styles.ButtonPrimary}`} text="Save" type="submit" name="submit" />
                    </div>


                    
                </form>
            </div>
        </div>
    </div>
}
