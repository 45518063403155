import styles from './EmployeePayroll.module.css'
import FormSelectWithoutLabel from '../../Symbols/App/Form/FormSelectWithoutLabel';
import ButtonGhost from '../../Symbols/App/Button/ButtonGhost';
import ButtonPrimary from '../../Symbols/App/Button/ButtonPrimary';
import ButtonTertiary from '../../Symbols/App/Button/ButtonTertiary';
import React, { useEffect, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import PayElement from '../../Symbols/Payments and Deductions/PayElement';
import DeductionElement from '../../Symbols/Payments and Deductions/DeductionElement';
import EmployeePayrollModalAddMemo from '../../Symbols/Employee/Employee Payroll/EmployeePayrollModalAddMemo';
import IconDocument from '../../Symbols/App/Icon/IconDocument';
import FileUpload from '../../Symbols/App/Files/FileUpload';
import fileListToBase64 from '../../helpers/FileHelper';
import Document from '../../Symbols/App/Files/Document';
import EmployeePayrollModalAddSSP from '../../Symbols/Employee/Employee Payroll/EmployeePayrollModalAddSSP';
import EmployeePayrollModalAddSMP from '../../Symbols/Employee/Employee Payroll/EmployeePayrollModalAddSMP';
import EmployeePayrollModalAddSPP from '../../Symbols/Employee/Employee Payroll/EmployeePayrollModalAddSPP';
import moment from 'moment';
import EmployeePayrollSearch from '../../Symbols/Dashboard/EmployeePayrollSearch';
import { useHttp } from '../../context/HttpProvider';

export default function EmployeePayroll({ employeePayrollId, navigate }) {
	const [payroll, setPayroll] = useState();
	const [error, setError] = useState(false);
	const [payments, setpayments] = useState([]);
	const [paymentOptions, setPaymentOptions] = useState([]);
	const [deductions, setDeductions] = useState([]);
	const [deductionOptions, setDeductionOptions] = useState([]);
	const [showAddMemoModal, setShowAddMemoModal] = useState(false);
	const [documents, setDocuments] = useState([]);
	const [readOnly, setReadOnly] = useState(false);
	const [showAddSSPModal,setShowAddSSPModal] = useState(false);
	const [showAddSMPModal,setShowAddSMPModal] = useState(false);
	const [showAddSPPModal,setShowAddSPPModal] = useState(false);
	const { get, post, put, deleteHttp } = useHttp();

	async function getPayroll() {
		await get(`employee-payrolls/${employeePayrollId}`)
			.then(async function (result) {
				if(result.payroll.statutoryMaternityPay != null){
					result.payroll.statutoryMaternityPay.readOnly = true;
				}
				if(result.payroll.statutoryPaternityPay != null){
					result.payroll.statutoryPaternityPay.readOnly = true;
				}

				setPayroll(result.payroll)
				setpayments(result.payroll.payElementList)
				setDeductions(result.payroll.deductionList)
				setDocuments(result.payroll.documents)
				setReadOnly(result.payroll.readOnly)
			}).catch(function () {
				setError(true);
			});
	}

	useEffect(() => {
		getPayroll();
	}, [employeePayrollId]);

	useEffect(() => {
		async function getPayElements() {
			await get(`employee-payrolls/pay-element-types?employeePayrollId=${employeePayrollId}`)
			.then(async function (result) {
				var pOptions = []
				result.payElementTypes.forEach(element => {
					pOptions.push({ text: element.name, value: element.id, ...element })
				});
				setPaymentOptions(pOptions);
			}).catch(function () {
				setError(true);
			});
		}
		getPayElements();
	},[employeePayrollId])

	useEffect(() => {
		async function getDeductions() {
			await get(`employee-payrolls/deduction-types?employeePayrollId=${employeePayrollId}`)
			.then(async function (result) {
				var dOptions = []
				result.deductionTypes.forEach(element => {
					dOptions.push({ text: element.name, value: element.id, ...element })
				});
	
				setDeductionOptions(dOptions);
			}).catch(function () {
				setError(true);
			});
		}
		getDeductions();
	}, []);

	function percentageString() {
		return `${payroll.currentEmployeeCount / payroll.totalEmployeeCount * 100}%`;
	}

	function getNextEmployeePayrollId(){
		var localStorageString = localStorage.getItem('employeePayrollIdArray');
		if(localStorageString === null){
			return null;
		}
		var employeePayrollIdArray = localStorageString.split(',')
		var curentIndex = employeePayrollIdArray.indexOf(employeePayrollId)
		if(curentIndex+1>employeePayrollIdArray.length){
			return null;
		}
		return employeePayrollIdArray[curentIndex+1]
	}

	function getPreviousEmployeePayrollId(){
		var localStorageString = localStorage.getItem('employeePayrollIdArray');
		if(localStorageString === null){
			return null;
		}
		var employeePayrollIdArray = localStorageString.split(',')
		var curentIndex = employeePayrollIdArray.indexOf(employeePayrollId)
		if(curentIndex-1 === -1){
			return null;
		}
		return employeePayrollIdArray[curentIndex-1]
	}

	async function saveNext(){
		await put('employee-payrolls', { employeePayrollId : employeePayrollId, Memo: payroll.memo, PayElementList: payments, DeductionList: deductions, StatutorySickPayList: payroll.statutorySickPay, StatutoryMaternityPay: payroll.statutoryMaternityPay , StatutoryPaternityPay: payroll.statutoryPaternityPay  })
		.then(async function (response) {
			//navigate to it 
			Next()
		})
		.catch(function () {
			setError('Error saving payroll please try again')
		});	
	}

	function Next(){
		if(getNextEmployeePayrollId() == null){
			navigate(`/payrollSummary/${payroll.payrollSubmissionId}/false`);
		}else{
			navigate(`/employeePayroll/${getNextEmployeePayrollId()}/`);
		}
	}

	function previous(){
		if(getPreviousEmployeePayrollId() == null){
			navigate(`/payrollSummary/${payroll.payrollSubmissionId}/false`);
		}else{
			navigate(`/employeePayroll/${getPreviousEmployeePayrollId()}/`);
		}
	}

	function addPayment(event) {
		if (event.currentTarget.value !== "" && !checkDuplicatePayment(event.currentTarget.value)) {
			var filteredItem = paymentOptions.filter(function (item) {
				return item.value === event.currentTarget.value
			})
			var newPayment = {id:filteredItem[0].value, value: filteredItem[0].defaultQuantity, rate: filteredItem[0].defaultRate, payElementTypeId:filteredItem[0].value,   payElementType: filteredItem[0] }
			setpayments([...payments, newPayment]);
		}
		event.currentTarget.value = '';
	}

	function checkDuplicatePayment(id){
		var filteredItem = payments.filter(function (item) {
			return item.payElementType.id === id
		})
		if(filteredItem.length>0){
			return true;
		}
		return false
	}

	function updatePaymentValue(id, value){
		var edited = payments.map(
			el => el.id === id? { ...el, value: value }: el
		)
		setpayments(edited);
	}

	function updatePaymentRate(id, rate){
		var edited = payments.map(
			el => el.id === id? { ...el, rate: rate }: el
		)
		setpayments(edited);
	}

	function deletePayment(id){
		var elements = [...payments];
		var filteredItems = elements.filter(function(item) {
			return item.id !== id
		  })
		setpayments(filteredItems);
	}

	function addDeduction(event){
		if(event.currentTarget.value!==""&& !checkDuplicateDeduction(event.currentTarget.value)){
			var filteredItem = deductionOptions.filter(function (item) {
				return item.value === event.currentTarget.value
			})
			var newDeduction = {id:filteredItem[0].value, value: filteredItem[0].defaultQuantity, rate: filteredItem[0].defaultRate, deductionTypeId:filteredItem[0].value, deductionType: filteredItem[0]}
			setDeductions([...deductions, newDeduction]);
		}
		event.currentTarget.value = '';
	}

	function checkDuplicateDeduction(id){
		var filteredItem = deductions.filter(function (item) {
			return item.id === id
		})
		if(filteredItem.length>0){
			return true;
		}
		return false
	}

	function updateDeductionValue(id, value){
		var edited = deductions.map(
			el => el.id === id? { ...el, value: value }: el
		)
		setDeductions(edited);
	}

	function updateDeductionRate(id, rate){
		var edited = deductions.map(
			el => el.id === id? { ...el, rate: rate }: el
		)
		setDeductions(edited);
	}

	function deleteDeduction(id){
		var elements = [...deductions];
		var filteredItems = elements.filter(function(item) {
			return item.id !== id
		  })
		  setDeductions(filteredItems);
	}

	
	function updateMemo(value){
		setPayroll(prevState => ({                   
				...prevState,
				memo: value
		}))
	}

	function addStatutorySickPay(value){
		var ssps = payroll.statutorySickPay;
		if(ssps === null){
			ssps = [];
		}
		ssps.push(value);

		setPayroll(prevState => ({
				...prevState,
				statutorySickPay: ssps
		}))
	}

	function removeStatutorySickPay(ssp){
		var ssps = payroll.statutorySickPay;
		ssps.splice(ssps.indexOf(ssp), 1);

		setPayroll(prevState => ({                   
				...prevState,
				statutorySickPay: ssps
		}))
	}

	function updateStatutoryMaternityPay(value){
		setPayroll(prevState => ({                   
				...prevState,
				statutoryMaternityPay: value
		}))
	}

	function removeStatutoryMaternityPay(){
		setPayroll(prevState => ({                   
				...prevState,
				statutoryMaternityPay: null
		}))
	}

	function updateStatutoryPaternityPay(value){
		setPayroll(prevState => ({                   
				...prevState,
				statutoryPaternityPay: value
		}))
	}

	function removeStatutoryPaternityPay(){
		setPayroll(prevState => ({                   
				...prevState,
				statutoryPaternityPay: null
		}))
	}

	function handleFileChange(event){
		event.persist();

		async function processFileUpload() {
			if (event.target.files) {
				let files = event.target.files;
				return await fileListToBase64(files);
			}
		}
		processFileUpload().then(async function (value) {
			setDocuments([...documents, ...value]);
			//send data to backend

			await post('employee-payrolls/add-documents', { employeePayrollId : employeePayrollId, documents: value })
			.then(async function (response) {
				//on responce push  url into file to reload the attachment.
				
				updateFiles(value, response.data.documents)
			})
			.catch(function () {
				setError('Error saving payroll please try again')
			});

			
		});
	};

	function updateFiles(oldFiles, newFiles){
		newFiles.forEach((element, index) => {
			var foundIndex = oldFiles.findIndex(x => x.fileName == element.fileName);
			oldFiles[foundIndex].azureBlobUrl = element.azureBlobUrl;
		});
		setDocuments([...documents, ...oldFiles]);
	}


	async function handelRemoveFile(file){

		await deleteHttp(`employee-payrolls/`, {Id : file.id})
			.then(async function (result) {
				var elements = [...documents];
				var filteredItems = elements.filter(function(item) {
					return item !== file
				})
				setDocuments(filteredItems);
			}).catch(function () {
				setError('Error removing document')
			});	
	}

	return <div className={`${styles.Content}`}>
		
		{payroll != null &&
			<>
				<EmployeePayrollSearch employeePayrollId={employeePayrollId} />
				{showAddMemoModal &&
					<EmployeePayrollModalAddMemo onClose={() => setShowAddMemoModal(false)} memo={payroll.memo} setMemo={updateMemo} readOnly={readOnly}/>
				}
				{showAddSSPModal &&
					<EmployeePayrollModalAddSSP onClose={() => setShowAddSSPModal(false)} setStatutorySickPay={addStatutorySickPay}  readOnly={readOnly}/>
				}
				{showAddSMPModal &&
					<EmployeePayrollModalAddSMP onClose={() => setShowAddSMPModal(false)} statutoryMaternityPay={payroll.statutoryMaternityPay} setstatutoryMaternityPay={updateStatutoryMaternityPay}  readOnly={readOnly}/>
				}
				{showAddSPPModal &&
					<EmployeePayrollModalAddSPP onClose={() => setShowAddSPPModal(false)} statutoryPaternityPay={payroll.statutoryPaternityPay} setstatutoryPaternityPay={updateStatutoryPaternityPay}  readOnly={readOnly}/>
				}
				<div className={`${styles.ContentTop}`}>
					<div className={`row`}>
						<div className={`columns small-12`}>
							<div className={`${styles.PayrollHeader}`}>
								<div className={`${styles.PayrollProgress}`} >
									<CircularProgressbarWithChildren value={payroll.currentEmployeeCount} maxValue={payroll.totalEmployeeCount} styles={buildStyles({
										// Colors
										pathColor: `#00D99F`,
										textColor: '#fff',
										trailColor: '#53518B',
									})} >
										<p className={`${styles.PayrollProgress_Text}`} ><span className={`${styles.PayrollProgress_Text_Bold}`} >{payroll.currentEmployeeCount}/</span>{payroll.totalEmployeeCount}</p>
									</CircularProgressbarWithChildren>
								</div>
								<div className={`${styles.PayrollDetails}`}>
									<span className={`${styles.PayrollDetails_Name}`}>{payroll.employeeName}</span>
									<span className={`${styles.PayrollDetails_NiNumber}`}>{payroll.niNumber}</span>
									<span className={`${styles.PayrollDetails_Date}`}>Payroll of {payroll.payrollDate}</span>
								</div>
							</div>
							<div className={`${styles.PayrollProgressBar}`} >
								<div style={{ width: percentageString() }} className={`${styles.PayrollProgressInnerBar} ${styles.stripes}`}></div>
							</div>
						</div>
					</div>
				</div>
				<div className={`${styles.ContentBottom}`}>
					<div className={`${styles.Row} row`}>
						<div className={`columns small-12`}>
							
							{payments && payments.length>0 &&
								<div className={`row collapse ${styles.PaymentHoursWrapper}`}>
									<div className={`columns small-6 medium-8 text-right`}>
										<div className={`${styles.PaymentNameWrapperWrapper}`}>
											<span className={`${styles.PaymentNameWrapperWrapper_PaymentName}`}>Payment name</span>
										</div>
									</div>
									<div className={`columns small-3 medium-2 text-right`}>
										<div className={`${styles.HoursNoWrapperWrapper}`}>
											<span className={`${styles.HoursNoWrapperWrapper_HoursNo}`}>Quantity</span>
										</div>
									</div>
									<div className={`columns small-3 medium-2 text-right`}>
										<div className={`${styles.HoursNoWrapperWrapper}`}>
											<span className={`${styles.HoursNoWrapperWrapper_HoursNo}`}>Rate</span>
										</div>
									</div>
								</div>
							}

							{payments &&
								payments.map((payElement, i) =>
									<PayElement key={i} payElement={payElement} onValueChangeHandler={updatePaymentValue} onRateChangeHandler={updatePaymentRate} canDelete={true} onDeleteHandler={deletePayment} readOnly={readOnly} />
								)
							}

							{deductions && deductions.length>0 &&
								<div className={`row collapse ${styles.DeductionsWrapper}`}>
									<div className={`columns small-6 medium-8 text-right`}>
										<div className={`${styles.PaymentNameWrapperWrapper}`}>
											<span className={`${styles.PaymentNameWrapperWrapper_PaymentName}`}>Deductions</span>
										</div>
									</div>
									<div className={`columns small-3 medium-2 text-right`}>
									<div className={`${styles.HoursNoWrapperWrapper}`}>
										<span className={`${styles.HoursNoWrapperWrapper_HoursNo}`}>Quantity</span>
									</div>
								</div>
								<div className={`columns small-3 medium-2 text-right`}>
									<div className={`${styles.HoursNoWrapperWrapper}`}>
										<span className={`${styles.HoursNoWrapperWrapper_HoursNo}`}>Rate</span>
									</div>
								</div>
								</div>
							}

							{deductions &&
								deductions.map((deductionElement, i) =>
									<DeductionElement key={i} deductionElement={deductionElement} onValueChangeHandler={updateDeductionValue} onRateChangeHandler={updateDeductionRate} canDelete={true} onDeleteHandler={deleteDeduction} readOnly={readOnly} />
								)
							}
						</div>
					</div>
					{!readOnly &&
					<>
						<div className={`${styles.Row} row`}>
							<div className={`columns small-12`}>
								<div className={`${styles.PaymentDeductionWrapper}`}>
									<div className={`${styles.FormSelectWithoutLabelWrapperWrapper}`}>
										<FormSelectWithoutLabel name="payment" placeholder="Add payment type" options={paymentOptions} onChange={addPayment}/>
									</div>
									<div className={`${styles.FormSelectWithoutLabelWrapperWrapper}`}>
										<FormSelectWithoutLabel name="deduction" placeholder="Add deduction type" options={deductionOptions} onChange={addDeduction}/>
									</div>
								</div>
							</div>
						</div>

						<div className={`${styles.Row} row`}>
							<div className={`columns small-12`}>
								<div className={`${styles.AddMemoWrapperWrapper}`}>
										<ButtonTertiary className={`${styles.ButtonTertiary}`} text={payroll.memo === '' ? "Add memo" : "Update memo"} name="addMeno" onClick={()=>setShowAddMemoModal(true)}/>
										<FileUpload className={`${styles.ButtonTertiary}`} name="documents" label="Add documents" onChange={handleFileChange} onRemoveFile={handelRemoveFile} value={documents} multiple/>
										<ButtonTertiary className={`${styles.ButtonTertiary}`} text="Add SSP" name="ssp" onClick={()=>setShowAddSSPModal(true)}/>
										<ButtonTertiary className={`${styles.ButtonTertiary}`} text={payroll.statutoryMaternityPay === null ? "Add SMP" : "Update SMP"} name="smp" onClick={()=>setShowAddSMPModal(true)}/>
										<ButtonTertiary className={`${styles.ButtonTertiary}`} text={payroll.statutoryPaternityPay === null ? "Add SPP" : "Update SPP"} name="spp" onClick={()=>setShowAddSPPModal(true)}/>
								</div>
							</div>
						</div>
					</>
					}
					{readOnly && 
					<div className={`${styles.Row} row`}>
						<div className={`columns small-12`}>
								<div className={`${styles.AddMemoWrapperWrapper}`}>
									{payroll.statutoryMaternityPay != null &&
										<div className={`${styles.AddMemoWrapperWrapperItem}`}>
											<button name="smp" onClick={()=>setShowAddSMPModal(true)}>
													<p className={`${styles.AddMemoWrapperWrapper_AddMemo}`}>View SMP</p>
											</button>
										</div>
									}
									{payroll.statutoryPaternityPay != null &&
										<div className={`${styles.AddMemoWrapperWrapperItem}`}>
											<button name="smp" onClick={()=>setShowAddSPPModal(true)}>
													<p className={`${styles.AddMemoWrapperWrapper_AddMemo}`}>View SPP</p>
											</button>
										</div>
									}
								</div>
						</div>
					</div>
					}

					{payroll.memo &&
						<div className={`${styles.Row} row`}>
							<div className={`columns small-12 ${styles.Footer}`}>
								<div className={`${styles.FooterColumnFullWidth}`}>
									<p className={`${styles.FooterTitle}`}>Memo</p>
									<div className={`${styles.FooterItem}`}>
										<p className={`${styles.FooterItemText}  ${styles.FooterMemoText}`} onClick={()=>setShowAddMemoModal(true)}>{payroll.memo}</p>
										{!readOnly &&
											<IconDocument className={`${styles.RemoveMemo}`} onClick={()=>updateMemo('')}/>
										}
									</div>
								</div>
							</div>
						</div>
					}

					{documents && documents.length>0 &&
						<div className={`${styles.Row} row`}>
							<div className={`columns small-12 ${styles.Footer}`}>
								<div className={`${styles.FooterColumnFullWidth}`}>
									<p className={`${styles.FooterTitle}`}>Documents</p>
									{documents &&
										documents.map((document, i) =>
										<Document document={document} onDelete={handelRemoveFile} readOnly={readOnly}/>
										)
									}
								</div>
							</div>
						</div>
					}

					{payroll.statutorySickPay != null && payroll.statutorySickPay.length > 0 &&
						<div className={`${styles.Row} row`}>
							<div className={`columns small-12 ${styles.Footer}`}>
								<div className={`${styles.FooterColumnFullWidth}`}>
									<p className={`${styles.FooterTitle}`}>Statutory sick pay</p>
									{
										payroll.statutorySickPay.map((ssp, i) =>
											<div className={`${styles.FooterItem}`} key={i}>
												<p className={`${styles.FooterItemText}  ${styles.FooterSMPText}`} >{moment(ssp.startDay).format("DD/MM/YYYY")} - {moment(ssp.lastDay).format("DD/MM/YYYY")}</p>
												{!readOnly &&
													<IconDocument className={`${styles.RemoveMemo}`} onClick={()=>removeStatutorySickPay(ssp)}/>
												}
											</div>
										)
									}
								</div>
							</div>
						</div>
					}

					{payroll.statutoryMaternityPay != null &&
						<div className={`${styles.Row} row`}>
							<div className={`columns small-12 ${styles.Footer}`}>
								<div className={`${styles.FooterColumnFullWidth}`}>
									<p className={`${styles.FooterTitle}`}>Statutory maternity pay</p>
									<div className={`${styles.FooterItem}`}>
										<p className={`${styles.FooterItemText}  ${styles.FooterSMPText}`} >Last day of work: {payroll.statutoryMaternityPay?.lastDayOfWork ? moment(payroll.statutoryMaternityPay.lastDayOfWork).format("DD/MM/YYYY") :  "Not Set" }</p>
										{!readOnly &&
											<IconDocument className={`${styles.RemoveMemo}`} onClick={()=>removeStatutoryMaternityPay()}/>
										}
									</div>
								</div>
							</div>
						</div>
					}

					{payroll.statutoryPaternityPay != null &&
						<div className={`${styles.Row} row`}>
							<div className={`columns small-12 ${styles.Footer}`}>
								<div className={`${styles.FooterColumnFullWidth}`}>
									<p className={`${styles.FooterTitle}`}>Statutory paternity pay</p>
									<div className={`${styles.FooterItem}`}>
										<p className={`${styles.FooterItemText}  ${styles.FooterSMPText}`} >Last day of work: {payroll.statutoryPaternityPay.lastDayOfWork ? moment(payroll.statutoryPaternityPay.lastDayOfWork).format("DD/MM/YYYY") :  "Not Set" }</p>
										{!readOnly &&
											<IconDocument className={`${styles.RemoveMemo}`} onClick={()=>removeStatutoryPaternityPay()}/>
										}
									</div>
								</div>
							</div>
						</div>
					}

					<div className={`${styles.Row} row`}>
						<div className={`columns small-12 text-right ${styles.ButtonWrapper}`}>
							{readOnly &&
								<ButtonPrimary className={`${styles.ButtonPrimary}`} name="next" text="Next" onClick={Next}/>
							}
							{!readOnly &&
								<ButtonPrimary className={`${styles.ButtonPrimary}`} name="next" text="Save / Next" onClick={saveNext}/>
							}
							<ButtonGhost className={`${styles.ButtonGhost}`} name="previous" text="Previous" onClick={previous}/>
						</div>
					</div>
				</div>
			</>
		}
	</div>
}
