import styles from './FilterButton.module.css'
import React, { useState } from 'react';
import IconChevronDown from '../Icon/IconChevronDown'

export default function FilterButton({className, text,onFilter, ...other}) {
	const [ascending, setAscending] = useState(true);

	function handleClick(){
		onFilter(!ascending, text)
		setAscending(!ascending)
		
	}

	return <button className={`${styles.FilterButton} ${className ? className : ''}`} onClick={handleClick} {...other}>
		{text}
		<IconChevronDown className={`${styles.Icon} ${ascending == false ? styles.Descending : ""}`}/>
	</button>
}
