import React, { useState } from 'react'
import styles from './EmployeePayrollSearch.module.css'
import { navigate } from '@reach/router'
import IconSearch from '../App/Icon/IconSearch';
import IconClose from '../App/Icon/IconCloseWhite';
import EmployeePayrollSearchResult from "./EmployeePayrollSearchResult";
import H2 from "../../TextStyles/H2";
import { useHttp } from '../../context/HttpProvider';

export default function EmployeePayrollSearch(employeePayroll) {
    const [error, setError] = useState('');
    const [showInput, setShowInput] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const { get } = useHttp();

    async function searchForPendingEmployeePayroll(e) {
        setSearchTerm(e.target.value);
        setError('');
        if (e.target.value.length > 2) {
            await search(e.target.value);
        } else {
            setSearchResults([]);
            await hideResults();
        }
    }

    async function search(name) {
        const result = await get(`employee-payrolls/search-employee-payroll?name=${name}&employeePayrollId=${employeePayroll.employeePayrollId}`)

        if (result.exists) {
            setSearchResults(result.results);
            showResults();
        } else {
            setSearchResults([]);
            setError('Employee not in this payroll.')
        }
    }

    function clearError() {
        setError('');
    }

    function go() {
        if (searchTerm.length >= 3){
            search(searchTerm);
        }
    }
    function closeSearch(){
        setShowInput(false)
        clearError()
        setSearchTerm('')
    }

    function openSearch(){
        setShowInput(true);
    }

    async function showResults(){
        var activeClass = `${styles.SearchMenuOpen}`;
        var menuElement = document.getElementById("searchMenu");
        var Transparency = document.getElementById("searchMenuTransparency");
        menuElement.classList.add(activeClass);
        Transparency.classList.add(activeClass);
    }
    async function hideResults(){
        var activeClass = `${styles.SearchMenuOpen}`;
        var menuElement = document.getElementById("searchMenu");
        var Transparency = document.getElementById("searchMenuTransparency");
        menuElement.classList.remove(activeClass);
        Transparency.classList.remove(activeClass);
    }

    return <>
    {showInput && 
        <div className={`${styles.SearchAlphaBackground}`} onClick={() => {hideResults(); closeSearch();}}></div>
    }
    
    <div className={`${styles.SearchWrapper}`}>
        {showInput && 
            <IconClose onClick={()=> closeSearch()}/>
        }
        
        <div className={`${styles.SearchInputWrapper}`}>
        {showInput && 
            <input className={`${styles.SearchInput}`}
                id="search-input"
                type="text"
                placeholder="Employee search"
                onFocus={() => clearError()}
                onChange={(e) => {setSearchTerm(e.target.value); searchForPendingEmployeePayroll(e)}}
                value={searchTerm}
                autoFocus
            />
        }
        {!showInput &&
            <p className={`${styles.SearchText}`}>Employee search</p>
        }
        <div className={showInput ? `${styles.SearchIconWrapperExtended} ${styles.SearchIconWrapper}` : `${styles.SearchIconWrapper}`}>
        {showInput &&
            <IconSearch className={`${styles.SearchIcon}`} onClick={()=>go()}/>
        }
        {!showInput &&
            <IconSearch className={`${styles.SearchIcon}`} onClick={()=> openSearch()}/>
        }
            
        </div>
        <div>
            <div id="searchMenuTransparency" className={`${styles.SearchMenuTransparency}`} onClick={() => {hideResults(); closeSearch();}}></div>
            <div id="searchMenu" className={`${styles.SearchMenu}`}>
                <div>
                    <EmployeePayrollSearchResult searchResults={searchResults} onResultSelected={() => {hideResults(); closeSearch();}}/>
                </div>
            </div>
        </div>
            
        </div>
        <span className={`${styles.Error}`}>{error}</span>
    </div>

    </>
}