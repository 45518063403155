import styles from './AddEmployee.module.css'
import React, { useState, useEffect } from 'react';
import useForm from '../../hooks/useForm';
import ButtonPrimary from '../../Symbols/App/Button/ButtonPrimary';
import FormInputText from '../../Symbols/App/Form/FormInputText';
import FormSelectWithoutLabel from '../../Symbols/App/Form/FormSelectWithoutLabel';
import FormInputCheckbox from '../../Symbols/App/Form/FormInputCheckbox';
import { NiCategories, Sex, MaritalStatus, Form } from '../../data/SelectData';
import DatePicker from '../../Symbols/App/DatePicker/DatePicker';
import { navigate } from '@reach/router';
import { useUser } from '../../context/UserProvider';
import WorkingDays from "../../Symbols/App/Working Days/WorkingDays";
import {
    ValidateNiNumber,
    NotEmptyString,
    ValidateDateOfBirth,
    ValidatePostcode,
    ValidateEmail,
    ValidateInt,
    ValidateTaxCode,
    ValidateNotNullLengthLessThanOrEqual30,
    ValidateLengthLessThanOrEqual30,
    ValidateNotNullLengthLessThanOrEqual10,
    ValidateLengthLessThanOrEqual2
} from '../../modules/Validation';
import moment from 'moment';
import FileUpload from "../../Symbols/App/Files/FileUpload";
import fileListToBase64 from "../../helpers/FileHelper";
import Document from "../../Symbols/App/Files/Document";
import LoadingModal from "../../Symbols/App/Loading/LoadingModal";
import { useHttp } from '../../context/HttpProvider';

export default function AddEmployee() {
    const { user } = useUser();
    const [error, setError] = useState([]);
    const { values, handleChange, handleCheckboxChange, handleSubmit, setInitialValues } = useForm(onFormSubmit);
    const [dateOfBirth, setDateOfBirth] = useState();
    const [startDate, setStartDate] = useState();
    const [departments, setDepartments] = useState([]);
    const [paymentDates, setPaymentDates] = useState();
    const [documents, setDocuments] = useState([]);
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [workingDaysDefault, setWorkingDaysDefault] = useState('NQQQQQN');
    const [taxCodeDefault, setTaxCodeDefault] = useState();
    const { get, post } = useHttp();

    async function getCompanyPaymentSchedules(){
		await get(`client-companies/schedule-dates-by-company-id`)
		.then(async function (result) {
            var pOptions = []
            if(result.scheduleDates){
                result.scheduleDates.forEach(element => {
                    pOptions.push({ text: element.displayText, value: element.id })
                });
                setPaymentDates(pOptions)
                setInitialValues({'scheduleDateId' : pOptions[0].value, 'workingDaysPattern' : workingDaysDefault});
            }
		}).catch(function (error) {
		});
    }

    useEffect(() => {
		getCompanyPaymentSchedules();
	}, []);
    
    async function onFormSubmit() {
        values.dateOfBirth = dateOfBirth;
        values.startDate = startDate;
        values.address = {
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            addressLine3: values.addressLine3,
            addressLine4: values.addressLine4,
            addressLine5: values.addressLine5,
            postalCode: values.postCode,
        }
        values.documents = documents;

        if(validate(values)){
            setShowLoadingModal(true);
            await post('employees', values)
            .then(async function (response) {
                setShowLoadingModal(false);
                navigate('/')
            })
            .catch(function (error) {
                setShowLoadingModal(false);
                if(error.response.data.length>0){
                    setError(error.response.data);
                }else{
                    setError(["Could not add Employee."]);
                }
            });
        }
    }

    function validate(values){
        setError();
        var errorList = document.getElementsByClassName("Validation_Failed");
        if(errorList.length>0){
            setError("Please fix any errors in the form.");
            return false;
        }else{
            return true;
        } 
    }

    useEffect(() => {
        async function getDepartments(){
            const result = await get(`departments`)
            setDepartments(result.items);
        }
        getDepartments()
    },[])

    async function handleDateOfBirthChange(date) {
        if (date !== undefined) {
            setDateOfBirth(date);
            setNiCategoryBasedOnDateOfBirth(date)

        }
    }

    async function handleStartDateChange(date) {
        if (date !== undefined) {
            setStartDate(date);
        }
    }

    async function handlePostCodeChange(event) {
        if (event.target.value.length <= 8) {
            handleChange(event);
        }
    }

    async function handleNINumberChange(event) {

        if (event.target.value.length <= 13) {
            handleChange(event);
        }
    }

    function setNiCategoryBasedOnDateOfBirth(date){
        var YearsAgo21 = new Date();
        YearsAgo21.setFullYear( YearsAgo21.getFullYear() - 21 );

        var YearsAgo65 = new Date();
        YearsAgo65.setFullYear( YearsAgo65.getFullYear() - 65 );
        
        //>21 go into A
        if(moment(date).isBefore(YearsAgo21)){
            values.niCategory = "A";
        }
        //<21 go into M 
        if(!moment(date).isBefore(YearsAgo21)){
            values.niCategory = "M";
        }

        //>65 go into B
        if(moment(date).isBefore(YearsAgo65)){
            values.niCategory = "B";
        }
    }

    function handleFormChange(event) {
        if (event.target.value == 1 && values.taxCode === undefined || values.taxCode.trim().length <= 0) {
            setTaxCodeDefault('10L');
            values.taxCode = '10L';
        }
        handleChange(event);
    }

    function handleFileChange(event){
        event.persist();

        async function processFileUpload() {
            if (event.target.files) {
                let files = event.target.files;
                return await fileListToBase64(files);
            }
        }
        processFileUpload().then(async function (value) {
            setDocuments([...documents, ...value]);
        });
    };

    function handleRemoveFile(file) {
        var elements = [...documents];
        var filteredItems = elements.filter(function(item) {
            return item !== file
        })
        setDocuments(filteredItems);
    }

    return <div className={`${styles.Content}`}>
        {showLoadingModal &&
            <LoadingModal />
        }
        <div className={`row`}>
            <div className={`columns large-8 large-offset-2 medium-10 medium-offset-1`}>
                <div className={`${styles.HeaderTitleWrapper}`}>
                    <div className={`${styles.TitleWrapperWrapper}`}>
                        <span className={`${styles.TitleWrapperWrapper_Title}`}>Add Employee</span>
                    </div>
                </div>
                <form className={`${styles.FormWrapper}`} onSubmit={handleSubmit}>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Title" name="title" onChange={handleChange} value={values.title || ''} required validateRule={NotEmptyString} toolTipText={"Must be 10 characters or less in length"} validateRule={ValidateNotNullLengthLessThanOrEqual10}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="First name" name="firstName" onChange={handleChange} value={values.firstName || ''} required toolTipText={"Must be 30 characters or less in length"} validateRule={ValidateNotNullLengthLessThanOrEqual30}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Last name" name="lastName" onChange={handleChange} value={values.lastName || ''} required toolTipText={"Must be 30 characters or less in length"} validateRule={ValidateNotNullLengthLessThanOrEqual30}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Initials" name="initials" onChange={handleChange} value={values.initials || ''} toolTipText={"Must be 2 characters or less in length"} validateRule={ValidateLengthLessThanOrEqual2}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Address Line 1" name="addressLine1" onChange={handleChange} value={values.addressLine1 || ''} required toolTipText={"Must be 30 characters or less in length"} validateRule={ValidateNotNullLengthLessThanOrEqual30}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Address Line 2" name="addressLine2" onChange={handleChange} value={values.addressLine2 || ''} toolTipText={"Must be 30 characters or less in length"} validateRule={ValidateLengthLessThanOrEqual30}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Address Line 3" name="addressLine3" onChange={handleChange} value={values.addressLine3 || ''} toolTipText={"Must be 30 characters or less in length"}  validateRule={ValidateLengthLessThanOrEqual30}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Address Line 4" name="addressLine4" onChange={handleChange} value={values.addressLine4 || ''} toolTipText={"Must be 30 characters or less in length"} validateRule={ValidateLengthLessThanOrEqual30}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Address Line 5" name="addressLine5" onChange={handleChange} value={values.addressLine5 || ''} toolTipText={"Must be 30 characters or less in length"} validateRule={ValidateLengthLessThanOrEqual30}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Post Code" name="postCode" onChange={handlePostCodeChange} value={values.postCode || ''} required validateRule={ValidatePostcode}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Email Address" name="emailAddress" onChange={handleChange} value={values.emailAddress || ''} required toolTipText={"Must be a valid email address"} validateRule={ValidateEmail}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <DatePicker label="Date of Birth" name="dateOfBirth" onChange={handleDateOfBirthChange} value={dateOfBirth || ''} required toolTipText={"Must be 16 or older"} validateRule={ValidateDateOfBirth}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <DatePicker label="Start Date" name="startDate" onChange={handleStartDateChange} value={startDate || ''} required />
                    </div>
                    <div className={`${styles.SelectInputWrapper}`}>
                        <FormSelectWithoutLabel label="NI Category" name="niCategory" onChange={handleChange} options={NiCategories} value={values.niCategory || ''} required disabled/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="NI Number" name="niNumber" onChange={handleNINumberChange} value={values.niNumber || ''} required toolTipText={'NI number correct format LLNNNNNNL'} validateRule={ValidateNiNumber}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Works Number" name="worksNumber" onChange={handleChange} value={values.worksNumber || ''}/>
                    </div>
                    <div className={`${styles.SelectInputWrapper}`}>
                        <FormSelectWithoutLabel label="Sex" name="gender" onChange={handleChange} options={Sex} value={values.gender || ''} required />
                    </div>
                    <div className={`${styles.SelectInputWrapper}`}>
                        <FormSelectWithoutLabel label="Marital Status" name="maritalStatus" onChange={handleChange} options={MaritalStatus} value={values.maritalStatus || ''} required />
                    </div>
                    <div className={`${styles.InputCheckbox }`}>
                        <FormInputCheckbox label="Enable Online Services" checked disabled />
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Have you conducted a Right To Work check?" value={"I'm recording this elsewhere"} required disabled />
                    </div>
                    <div className={`${styles.SelectInputWrapper}`}>
                        <FormSelectWithoutLabel label="Which form has been provided?" name="form" onChange={handleFormChange} options={Form} value={values.form || ''} required />
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Tax Code" name="taxCode" onChange={handleChange} value={values.taxCode || ''} required validateRule={ValidateTaxCode}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Payment Method" name="paymentMethod" onChange={handleChange} value={'BACS'} required disabled />
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Gross Salary For Year" name="grossSalaryForYear" onChange={handleChange} value={values.grossSalaryForYear || ''} required />
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <WorkingDays name="workingDays" pattern={workingDaysDefault} onSelect={(value) => values.workingDaysPattern = value}/>
                    </div>
                    <div className={`${styles.InputWrapper}`}>
                        <FormInputText label="Hours Worked In The Week" required={true} name="hoursWorkedInWeek" onChange={handleChange} value={values.hoursWorkedInWeek || ''} />
                    </div>
                    <div className={`${styles.SelectInputWrapper}`}>
                        <FormSelectWithoutLabel label="Department" name="departmentId" onChange={handleChange} options={departments} value={values.departmentId || ''} placeholder={"Please select"}/>
                    </div>
                    <div className={`${styles.SelectInputWrapper}`}>
                        <FormSelectWithoutLabel label="Payment date" name="scheduleDateId" onChange={handleChange} placeholder={""} options={paymentDates} value={values.scheduleDateId || ''} required />
                    </div>

                    <FileUpload className={`${styles.ButtonTertiary}`} name="documents" label="Add documents" onChange={handleFileChange} onRemoveFile={handleRemoveFile} value={documents} multiple/>

                    {documents && documents.length>0 &&
                        <div className={`${styles.Row} ${styles.DocumentRow} row`}>
                            <div className={`columns small-12 ${styles.Footer}`}>
                                <div className={`${styles.FooterColumnFullWidth}`}>
                                    {documents &&
                                        documents.map((document, i) =>
                                            <Document key={documents.indexOf(document)} document={document} onDelete={handleRemoveFile}/>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {error &&
                        error.map((text, i) =>
                        <div key={i} className={`${styles.ErrorWrapper}`}>
                            <span className={`${styles.Error}`}>{text}</span>
                        </div>
                        )  
                    }                    
                    <div className={`${styles.ButtonWrapper}`}>
                        <ButtonPrimary className={`${styles.ButtonGhost}`} text="Add employee" type="submit" name="submit" />
                    </div>
                </form>
            </div>
        </div>
    </div>
}
