import styles from './PaymentDeduction.module.css'
import React, {useEffect, useState}  from 'react';
import PaymentDeductionFormInput from './PaymentDeductionFormInput';
import IconDelete from '../App/Icon/IconDelete';
import {ElementStatus} from '../../data/Enums'

export default function PayElement({payElement, canDelete, onValueChangeHandler, onRateChangeHandler, onDeleteHandler,  readOnly}) {
    const [rateReadOnly, setRateReadOnly] = useState(false);
    const [valueReadOnly, setValueReadOnly] = useState(false);

    useEffect(() => {
        const quantityInput = document.getElementById(getQuantityElementId(payElement.id));
        quantityInput.focus();
    },[readOnly]);

    const getQuantityElementId = (id) => `quantity-${id}`;

    function onValueChange(event){
        onValueChangeHandler(payElement.id, event.currentTarget.value)
    }

    function onRateChange(event){
        onRateChangeHandler(payElement.id, event.currentTarget.value)
    }

    useEffect(() => {
        if(payElement.payElementType.status == ElementStatus.FIXED || 
           payElement.payElementType.status == ElementStatus.FACTOR) {
			setRateReadOnly(true)
        } else if(payElement.payElementType.status == ElementStatus.GLOBAL) {
            setRateReadOnly(true)
            setValueReadOnly(true)
		}
    }, [readOnly]);
    
    return <div className={`row collapse ${styles.PaymentDeductionWrapper}`}>
        <div className={`columns small-6 medium-8 text-right`}>
            <div className={`${styles.PaymentDeductionLabelWrapper}`}>
                <span className={`${styles.PaymentDeduction_Label}`}>{payElement.payElementType.name}</span>
            </div>
        </div>
        <div className={`columns small-3 medium-2 ${styles.PaymentDeductionValueWrapper}`}>
            <PaymentDeductionFormInput className={`${styles.PaymentDeduction_Value}`} name={`${payElement.payElementType.text}-Value`} value={payElement.value} onChange={onValueChange}  disabled={valueReadOnly} id={getQuantityElementId(payElement.id)} />
        </div>
        <div className={`columns small-3 medium-2 ${styles.PaymentDeductionValueWrapper}`}>
            <PaymentDeductionFormInput className={`${styles.PaymentDeduction_Value}`} name={`${payElement.payElementType.text}-Rate`} value={payElement.rate} onChange={onRateChange}  disabled={rateReadOnly}/>
            {canDelete && !readOnly &&
                <div onClick={()=> onDeleteHandler(payElement.id)}>
                <IconDelete className={`${styles.IconDelete}`}/>
                </div>
            }
        </div>
    </div>
}