import styles from './FiveColumnRow.module.css'
import React from 'react';

export default function FiveColumnRow({className, employee, onClick}) {
	return <button name={employee.firstName} className={`employeeDetailsBtn ${className ? className : ''}`} onClick={onClick}>
		<div className={`${styles.Row} row text-right collapse`}>
			<div className={`columns small-6 medium-3`}>
				<div className={`${styles.TextColumn1WrapperWrapper}`}>
					<span className={`${styles.TextColumn1WrapperWrapper_TextColumn1}`}>
						{employee.firstName} {employee.lastName}
					</span>
				</div>
			</div>
			<div className={`columns small-6 medium-2`}>
				<div className={`${styles.TextColumn3WrapperWrapper}`}>
					<span className={`${styles.TextColumn3WrapperWrapper_TextColumn3}`}>
						{employee.payroll}
					</span>
				</div>
			</div>
			<div className={`columns small-0 medium-3`}>
				<div className={`${styles.TextColumn4WrapperWrapper}`}>
					<span className={`${styles.TextColumn4WrapperWrapper_TextColumn4}`}>
					{employee.department}
					</span>
				</div>
			</div>
			<div className={`columns small-0 medium-2`}>
				<div className={`${styles.TextColumn2WrapperWrapper}`}>
					<span className={`${styles.TextColumn2WrapperWrapper_TextColumn2}`}>
					{employee.insuranceId}
					</span>
				</div>
			</div>
			<div className={`columns small-0 medium-2`}>
				<div className={`${styles.TextColum5WrapperWrapper}`}>
					<span className={`${styles.TextColum5WrapperWrapper_TextColum5}`}>
					{employee.status}
					</span>
				</div>
			</div>
		</div>
	</button>
}
