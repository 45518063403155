import styles from './PayrollSummaryList.module.css'
import React, { useEffect, useState } from 'react';
import PayrollSummaryListItem from './PayrollSummaryListItem';
import ButtonGhost from '../App/Button/ButtonGhost';
import ButtonPrimary from '../App/Button/ButtonPrimary';
import LoadingModal from '../App/Loading/LoadingModal';
import {navigate} from '@reach/router';
import LoadingIndicator from '../App/Loading/LoadingIndicator';
import FormInputCheckbox from '../App/Form/FormInputCheckbox';
import NewPayrollModal from "./NewPayrollModal";
import PayrollSubmissionConfirmationModal from "./PayrollSubmissionConfirmationModal";
import SearchInput from '../App/Search/SeachInput';
import FormSelectWithoutLabel from '../App/Form/FormSelectWithoutLabel';
import { useHttp } from '../../context/HttpProvider';

export default function PayrollSummaryList({payrollId, setTitle, clearSelectedPayrollItems}) {
	const [render, setRender] = useState(false);
	const [summary, setSummary] = useState('');
	const [canSubmit, setCanSubmit] = useState(false);
	const [canCopy, setCanCopy] = useState(false);
	const [error, setError] = useState(false);
	const [showLoadingModal, setShowLoadingModal] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [searchString, setSearchString] = useState("");
	const [sortBy, setSortBy] = useState("");
	const { get, post, put } = useHttp();

	var sortByOptions = [
		{ text: "Name", value: "Name" },
		{ text: "Department", value: "Department" },
		{ text: "Works Number", value: "WorksNumber" }
	];


	async function getPayrollSummary(){
		await get(`payroll-submissions/summary?PayrollSubmissionId=${payrollId}`)
		.then(async function (result) {
			setSummary(result.summaryItems)
			setCanSubmit(result.canSubmit)
			setCanCopy(result.canCopy)
			setTitle(result.title)
			setRender(true)
			if(result.canCopy){
				//read only not checkboxes to edit so needs all ids to prev / next through all employee payrolls
				pushAllIdsToLocalStorage(result.summaryItems)
			}
		}).catch(function (error) {
			setError(error.response.data);
		});
	}

	function pushAllIdsToLocalStorage(summaryItems){
		var idArray = []
		summaryItems.forEach(item => {
			idArray.push(item.id)
		});
		localStorage.setItem('employeePayrollIdArray', idArray);
	}

	async function submitPayroll(){
		setShowConfirmationModal(false)
		setShowLoadingModal(true)
		await post('payroll-submissions/submit-payroll', { payrollSubmissionId: payrollId })
			.then(async function () {
				setShowLoadingModal(false)
				navigate(`/payroll`);
			})
			.catch(async function (error) {
				setShowLoadingModal(false)
				setError(error.response.data);
			})
	}

	async function copyPayroll(){
		setShowLoadingModal(true)
		await post('payroll-submissions/from-previous/', { copyFromPayrollSubmissionId: payrollId })
		.then(async function (response) {
			setShowLoadingModal(false)
			setRender(false)
            localStorage.removeItem('employeePayrollIdArray');
			navigate(`/payrollSummary/${response.data.payrollSubmissionId}/true`);
		})
		.catch(function (error) {
			setShowLoadingModal(false)
			setError(error.response.data);
		});
	}

	function checkShowEdit(){
		var checkboxes = document.querySelectorAll('#summary-wrapper input[type=checkbox]:checked');
		setShowEdit(checkboxes.length > 0)
	}

	function EditPayroll(){
		var checkboxes = document.querySelectorAll('#summary-wrapper input[type=checkbox]:checked');
		var idArray = []
		checkboxes.forEach(checkbox => {
			idArray.push(checkbox.id)
		});
		localStorage.setItem('employeePayrollIdArray', idArray);
		navigate(`/employeePayroll/${idArray[0]}/`);
	}

	function selectAllEmployeePayrolls(){
		setShowEdit(!selectAll)
		setSelectAll(!selectAll);
	}

	function displayConfirmationModal(value){
		setShowConfirmationModal(value);
	}

	function search(searchString){
		setSearchString(searchString);
	}

	function clearSearch(){
		setSearchString("");
	}

	function searchPredicate(searchString) {
		const searchTerm = searchString.toLowerCase();
		return item => item.name.toLowerCase().includes(searchTerm) ||
					   item.worksNumber.toString().includes(searchTerm) || 
					   item.department.toLowerCase().includes(searchTerm);
	}

	async function handleChangeSortBy(event) {
		setSortBy(event.target.value)
		
	}

	function sortByFunction(a,b){
		if(sortBy === "Name"){
			return a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)
		}
		if(sortBy === "Department"){
			return a.department.localeCompare(b.department)

		}
		if(sortBy === "WorksNumber"){
			return a.worksNumber - b.worksNumber;
		}
	}

	useEffect(() => {
		if(clearSelectedPayrollItems === 'true'){
			localStorage.removeItem('employeePayrollIdArray');
		}else{
			if(localStorage.getItem('employeePayrollIdArray') != null){
				setShowEdit(true)
			}
		}
	}, []);

	useEffect(() => {
		getPayrollSummary()
	}, [payrollId]);

	useEffect(() => {
	}, [sortBy]);

	return <>

		{render === false &&
			<div className={`${styles.LoadingWrapper}`}>
				<LoadingIndicator />
			</div>
			}
		{render && 
			<>
				{showLoadingModal &&
					<LoadingModal />
				}
				{showConfirmationModal &&
					<PayrollSubmissionConfirmationModal onClose={() => displayConfirmationModal(false)} onConfirm={() => submitPayroll()}/>
				}
				<div className={`row ${styles.HideInPrint} ${styles.SearchSortWrapper}`}>
					<div className={`columns small-12 medium-8 large-9`}>
						<SearchInput className={`${styles.SearchComponent}`} 
									label="Search" 
									name="search" 
									search={search} 
									clearSearch={clearSearch} 
									minimumSearchLength={1} />
					</div>
					<div className={`columns small-12 medium-4 large-3`}>
						<FormSelectWithoutLabel label={"Sort by"} options={sortByOptions} name="sortBy" onChange={handleChangeSortBy} value={sortBy} slim={true} />
					</div>
				</div>
				<div className={`${styles.ButtonWrapperTop}`}>
					<ButtonGhost text="Print" onClick={() => window.print()} />
					{canCopy && canCopy === true &&
						<ButtonPrimary text="Copy payroll" onClick={() => copyPayroll()}/>
					}
					{canSubmit && canSubmit === true &&
						<ButtonPrimary text="Submit payroll" onClick={() => displayConfirmationModal(true)}/>
					}
				</div>
				{error &&
					<p className={`${styles.Error}`}>{error}</p>
				}
				<div className={`row collapse ${styles.MobileListHeaderWrapper}`}>
					{canSubmit &&
						<div className={`columns small-12`}>
							<FormInputCheckbox checked={selectAll} onChange={() => selectAllEmployeePayrolls()} id="masterCheckbox" label="Select all"/>
						</div>
					}
				</div>
				<div className={`row collapse ${styles.ListHeaderWrapper}`}>

					<div className={`columns small-3`}>
						{canSubmit &&
							<div className={`row collapse `}>
								<div className={`columns small-2`}>
								<FormInputCheckbox checked={selectAll} onChange={() => selectAllEmployeePayrolls()} id="masterCheckbox"/>
								</div>
								<div className={`columns small-10`}>
								<p className={`${styles.HeaderText}`}>Name</p>
								</div>
							</div>
						}
						{!canSubmit &&
							<>	
								<p className={`${styles.HeaderText}`}>Name</p>
							</>
						}
					</div>
					<div className={`columns small-2`}>
						<p>Addition</p>
					</div>
					<div className={`columns small-4`}>
						<div className={`row`}>
							<p className={`columns small-6 ${styles.HeaderText}`}>Payment / deductions</p>
							<p className={`columns small-1 ${styles.HeaderText}`}>Qty</p>
							<p className={`columns small-5 end ${styles.PaymentDeductionAmount} ${styles.HeaderText}`}>Amount</p>
						</div>
						
					</div>
					<div className={`columns small-3`}></div>
				</div>
				<div id="summary-wrapper">
					{summary && summary.filter(searchPredicate(searchString)).sort(sortByFunction).map((summaryItem, i) =>
								<PayrollSummaryListItem key={i} data={summaryItem} setShowEdit={checkShowEdit} showEdit={canSubmit} selectAll={selectAll} checked={localStorage.getItem('employeePayrollIdArray') === null ? false : localStorage.getItem('employeePayrollIdArray').includes(summaryItem.id)} />
							)
					}
				</div>

				{error &&
					<p className={`${styles.Error}`}>{error}</p>
				}
		
				<div className={`${styles.ButtonWrapper}`}>
					<ButtonGhost text="Print" onClick={() => window.print()} />
					{canCopy && canCopy === true &&
						<ButtonPrimary text="Copy payroll" onClick={() => copyPayroll()}/>
					}
					{canSubmit && canSubmit === true &&
						<ButtonPrimary text="Submit payroll" onClick={() => submitPayroll()}/>
					}
				</div>
				<div className={showEdit === true ? `${styles.EditPayrollWrapper} ${styles.EditActive}` : `${styles.EditPayrollWrapper}` }>
					<ButtonPrimary text="Edit payroll" onClick={() => EditPayroll()}/>
				</div>
			</>
		}
	</>
}


