import styles from './EmployeeBankDetailsModal.module.css'
import H2 from '../../TextStyles/H2';
import useForm from '../../hooks/useForm';
import FormInputText from '../../Symbols/App/Form/FormInputText';
import FormInputTextNoLabel from '../../Symbols/App/Form/FormInputTextNoLabel'
import ButtonPrimary from '../../Symbols/App/Button/ButtonPrimary';
import React, {useState}from 'react';
import Modal from '../../modal/Modal';
import { navigate } from '@reach/router';
import {ValidateBankAccountNumber, ValidateBankSortcode} from '../../modules/Validation'
import { useHttp } from '../../context/HttpProvider';
import FormSelectWithoutLabel from "../../Symbols/App/Form/FormSelectWithoutLabel";
import {AccountType, Form} from "../../data/SelectData";

export default function EmployeeBankDetailsModal({employeeId,...others}) {
	const [error, setError] = useState(false);
	const { values, handleChange, handleSubmit } = useForm(onFormSubmit);
	const { post } = useHttp();

	async function onFormSubmit() {
		if(validate()){
			values.employeeId = employeeId;
			values.accountNumber = String(values.accountNumber);
			await post('employees/bank-details', values)
            .then(async function (response) {
				if(response.data.success === false && response.data.error != ""){
					setError(response.data.error);
				}else{
					navigate(`/employeeDetails/${employeeId}/`)
				}
            })
            .catch(function () {
                setError("Could not save bank details.");
            });
		}
	}

	function validate(values){
        setError();
        var errorList = document.getElementsByClassName("Validation_Failed");
        if(errorList.length>0){
            setError("Please fix any errors in the form.");
            return false;
        }else{
            return true;
        } 
    }

	function handelAccountNumberChange(event){
		setError();
		if(event.target.value.length<9){
			handleChange(event)
		}
	}

	function handleAccountNameChange(event){
		setError();
		handleChange(event);
	}

	function handleAccountTypeChange(event){
		setError();
		handleChange(event);
	}

	function handelSortcodeChange(event){
		setError();
		if(event.target.value.length>2){
			if(event.target.id != 3){
				document.getElementById(parseInt(event.target.id)+1).value = event.target.value.slice(-1);
				document.getElementById(parseInt(event.target.id)+1).focus();
			}
		}else{
			handleChange(event)
		}
	}

	return <Modal id="bank-modal" {...others}>
	<div className={`${styles.ModalTitle}`}>
		<H2 className={`${styles.AddANewUser_H2}`}>Bank account details</H2>
	</div>
	<form className={`${styles.FormWrapper}`} onSubmit={handleSubmit}>
		<div className={`${styles.SelectInputWrapper}`}>
			<FormSelectWithoutLabel label="Type of account" name="accountType" onChange={handleAccountTypeChange} options={AccountType} value={values.accountType || ''} required />
		</div>
		<div className={`${styles.InputWrapper}`}>
			<FormInputText label="Account name" type="text" name="accountName" onChange={handleAccountNameChange} value={values.accountName || ''} required toolTipText={'Account name is required'}/>
		</div>
		<div className={`${styles.InputWrapper}`}>
			<FormInputText label="Account number" type="number" min="1" max="99999999" step="1" name="accountNumber" onChange={handelAccountNumberChange} value={values.accountNumber || ''} required toolTipText={'Account number is 8 digits'} validateRule={ValidateBankAccountNumber}/>
		</div>
		<div className={`${styles.SortcodeInputWrapper}`}>
			<FormInputText className={`${styles.SortcodeInput}`} id="1" label="Sortcode" name="sortcodePart1"  type="number" min="0" max="99" step="1" onChange={handelSortcodeChange} value={values.sortcodePart1 || ''} required validateRule={ValidateBankSortcode}/>
			<FormInputTextNoLabel className={`${styles.SortcodeInput}`} id="2" name="sortcodePart2" type="number" min="0" max="99" step="1" onChange={handelSortcodeChange} value={values.sortcodePart2 || ''} required validateRule={ValidateBankSortcode}/>
			<FormInputTextNoLabel className={`${styles.SortcodeInput}`} id="3" name="sortcodePart3" type="number" min="0" max="99" step="1" onChange={handelSortcodeChange} value={values.sortcodePart3 || ''} required toolTipText={'Sortcode is 6 digits in groups of 2'} validateRule={ValidateBankSortcode}/>
		</div>
		{error &&
			<div className={`${styles.InputWrapper}`}>
				<span className={`${styles.Error}`}>{error}</span>
			</div>
		}
		<div className={`${styles.ButtonWrapper}`}>
			<ButtonPrimary className={`${styles.ButtonPrimary}`} text="Save" type="submit" name="submit"/>
		</div>
	</form>
</Modal>

}
