import React from 'reactn';
import './App.css';
import {Router} from '@reach/router';
import LoginCallback from './pages/App/LoginCallback.js';
import Secure from './pages/App/Secure.js';
import Auth from './modules/auth/auth';
import 'react-app-polyfill/ie11';
import 'core-js';
import HeaderLoggedIn from './Symbols/App/Header/HeaderLoggedIn';
import { UserProvider } from './context/UserProvider';
import { HttpProvider } from './context/HttpProvider';
import Dashboard from './pages/Dashboard/Dashboard';
import EmployeePayroll from './pages/Employee/EmployeePayroll';
import EmployeeDetails from './pages/Employee/EmployeeDetails';
import AddEmployee from './pages/Employee/AddEmployee';
import EditEmployee from './pages/Employee/EditEmployee';
import PayrollSummary from './pages/Payroll/PayrollSummary'


const NotFound = () => <span>Sorry, page not found</span>;

function App() {

    let auth = new Auth();

    return (
        <UserProvider auth={auth}>
            <HttpProvider>
                <HeaderLoggedIn auth={auth}/>
                <Router primary={false}>
                    <NotFound default/>
                    <LoginCallback path='/callback' auth={auth}/>
                    <Secure path='/' auth={auth}>
                        <Dashboard path='/' />
                        <Dashboard path='/:tab/*'/>
                        <EmployeePayroll path="/employeePayroll/:employeePayrollId"/>
                        <EmployeeDetails path="/employeeDetails/:employeeId/*"/>
                        <AddEmployee path="/add-employee" />
                        <EditEmployee path="/edit-employee/:employeeId/*" />
                        <PayrollSummary path="/payrollSummary/:payrollId/:clearSelectedPayrollItems" />
                    </Secure>
                </Router>
            </HttpProvider>
        </UserProvider>
    );
}

export default App;