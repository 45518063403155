import styles from './HeaderDropdownMenu.module.css'
import Avatar from '../../../Images/Avatar.svg';
import React, { useState, useEffect } from 'react';
import NavigationDropDown from '../Navigation/NavigationDropdown';
import { useUser } from '../../../context/UserProvider';

export default function HeaderDropdownMenu({auth}) {
	const [showMenu, setShowMenu] = useState(false);
	const { user } = useUser();

	function closeMenu(){
		setShowMenu(false)
	}

	useEffect(() => {
	}, [user]);

	return <div className={`${styles.MenuWrapper} columns small-6 text-right`}>
		{showMenu &&
			<div id="menuTransparency" className={`${styles.MenuTransparency}`} onClick={closeMenu}></div>
		}
		
		<div className={`menu-btn ${styles.AvatarWrapper}`} onClick={() => setShowMenu(!showMenu)}>
			<img className={`${styles.Avatar}`} src={Avatar} alt="Avatar" />
		
		{showMenu &&
			<div className={`${styles.DropdownWrapper}`}>
				<NavigationDropDown onClose={closeMenu} auth={auth} isClientAdmin={user.isClientAdmin}/>
			</div>
		}
		</div>
	</div>
}
