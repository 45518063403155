import Close from '../Images/Close.svg';
import React from 'react';
import styles from './Modal.module.css'

export default function Modal({ children, width, onClose, ...others }) {

    let style = '';



    return (<div className="modal" id="modal" className='modal--open'>
        <div className="modal_overlay"></div>
        <div className="modal_content">
            <div className={`${styles.Dialog} ${style}`}>
                <button onClick={onClose} title={'Close'}>
                    <img src={Close} alt={'Close'} className={`${styles.Close}`} />
                </button>
                {children}
            </div>
        </div>
    </div>)
}