import {UserManager, User} from 'oidc-client';
import {expiresKey, accessTokenKey, idTokenKey} from './auth-constants';

const jwtDecode = require('jwt-decode');

export default class Auth {

    public static getToken = (): string => {
        return (localStorage.getItem(accessTokenKey)) as string;
    };

    private loggers: Array<(message: string) => void> = [];
    private authURL = process.env.REACT_APP_AUTH_URL;
    private baseUrl = process.env.REACT_APP_BASE_URL;

    private config = {
        authority: `${this.authURL}`,
        client_id: "client",
        redirect_uri: `${this.baseUrl}/callback`,
        response_type: "id_token token",
        scope: "openid profile api",
        post_logout_redirect_uri: `${this.baseUrl}/login`
    };

    private mgr: UserManager;
    private loginAttempt: number = 0;

    constructor() {
        this.mgr = new UserManager(this.config);
    }

    public registerLogger(logger: (message: string) => void) {
        this.loggers.push(logger);
    }

    public isAuthenticated = () => {
        if (process.env.REACT_APP_BYPASS_AUTH && process.env.REACT_APP_BYPASS_AUTH.toLowerCase() === 'true') {
            return true;
        }
        if (localStorage.getItem(expiresKey)) {
            const expiresAt = JSON.parse(localStorage.getItem(expiresKey) as string);
            return new Date().getTime() < expiresAt;
        }
        return false;
    };

    public login = async () => {
        const result = await this.mgr.signinRedirect();
        this.log(JSON.stringify(result));
    };

    public logout = async () => {
        localStorage.removeItem(accessTokenKey);
        localStorage.removeItem(idTokenKey);
        localStorage.removeItem(expiresKey);

        const result = await this.mgr.signoutRedirect();
        this.log(JSON.stringify(result));
    };

    public completeSignin = async () => {
        try {
            this.loginAttempt = 0; // reset back to zero as we've managed to log in
            const result = await this.mgr.signinRedirectCallback();
            await Auth.setSession(result);
            this.log(JSON.stringify(result));
        } catch (ex) {
            if (ex.message === 'No matching state found in storage' && this.loginAttempt <= 3) {
                this.loginAttempt++;
                this.log(`login attempt ${this.loginAttempt}`);
                await this.login();
            }
            throw ex;
        }
    };

    public api = async () => {
        const user = await this.mgr.getUser();
        this.log(JSON.stringify(user));
    };

    private log = (message: string) => {
        if (this.loggers.length > 0) {
            this.loggers.map(logger => logger(message));
        }
    };

    public getUserClaims = async () => {
        const token = localStorage.getItem(accessTokenKey) as string;
        const claims = jwtDecode(token);
        let userClaims: { [k: string]: any } = {
            roles: claims.role
        };

        // Being defensive here as the email claim was 
        // an array in testing, even though a string 
        // is being set when claims are set on the server
        if (Array.isArray(claims.email)) {
            userClaims.EmailAddress = claims.email[0]
        } else {
            userClaims.EmailAddress = claims.email
        }
        userClaims.Name = claims.name;
        return userClaims;
    };

    private static setSession(authResult: User) {
        if (authResult.expires_in) {
            const expiresAt = JSON.stringify((authResult.expires_in * 1000) + new Date().getTime());
            localStorage.setItem(expiresKey, expiresAt);
        }
        localStorage.setItem(accessTokenKey, authResult.access_token);
        localStorage.setItem(idTokenKey, authResult.id_token);
    }

}