import React, {useState} from 'react';
import EmployeePayrollSearchResultItem from "./EmployeePayrollSearchResultItem";
import styles from './EmployeePayrollSearchResult.module.css';
import {navigate} from "@reach/router";

export default function EmployeePayrollSearchResult({searchResults, onResultSelected}) {

    return <div data-cy="results-list" className={`${styles.ResultsList}`}>
        {searchResults && searchResults.length > 0 &&
            searchResults.map(item => <EmployeePayrollSearchResultItem resultItem={item} key={item.niNumber} onResultSelected={onResultSelected}/>)
        }
    </div>
}
