import React from 'react';
import styles from './LoadingModal.module.css'
import LoadingIndicator from './LoadingIndicator'

export default function LoadingModal({ children, width, onClose, ...others }) {

    let style = '';



    return (<div className="modal" id="modal" className='modal--open'>
        <div className="modal_overlay"></div>
        <div className="modal_content">
            <div className={`${styles.Dialog} ${style}`}>
                <LoadingIndicator modal={true}/>
            </div>
        </div>
    </div>)
}