import styles from './PayrollSummary.module.css'
import React, {useState, useEffect} from 'react';
import PayrollSummaryList from '../../Symbols/Payroll/PayrollSummaryList';


export default function PayrollSummary({payrollId, clearSelectedPayrollItems}) {
	const [title, setTitle] = useState('');

	return <div className={`${styles.ContentWrapper}`}>
			<div>
				<div className={`row ${styles.Content}`}>
					<div className={`${styles.Row} row collapse`}>
						<div className={`columns small-12`}>
							<div className={`${styles.TitleWrapperWrapper} text-center`}>
								<p className={`${styles.TitleWrapperWrapper_Title}`}>
									Payroll summary
								</p>
								<p className={`${styles.TitleWrapperWrapper_SubTitle}`}>
									{title}
								</p>
							</div>
						</div>
					</div>
					<PayrollSummaryList payrollId={payrollId} setTitle={setTitle} clearSelectedPayrollItems={clearSelectedPayrollItems}/>
				</div>
			</div>
		</div>
}
