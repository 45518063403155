export default async function fileListToBase64(fileList) {
    // create function which return resolved promise
    // with data:base64 string
    function getBase64(file) {
        const reader = new FileReader()
        return new Promise(resolve => {
            reader.onload = ev => {
                var arrayBuffer = reader.result;
                var fileData = arrayBuffer.substring(arrayBuffer.indexOf(",") + 1);
                var fileType = base64MimeType(arrayBuffer);

                var documentData = {
                    file: fileData,
                    fileName: file.name,
                    fileType: fileType
                }
                resolve(documentData)
            }
            reader.readAsDataURL(file)
        })
    }
    // here will be array of promisified functions
    const promises = [];

    // loop through fileList with for loop
    for (let i = 0; i < fileList.length; i++) {
        promises.push(getBase64(fileList[i]));
    }

    // array with base64 strings
    return await Promise.all(promises);
}

function base64MimeType(encoded) {
    var result = null;
    if (typeof encoded !== 'string') {
        return result;
    }
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
        result = mime[1];
    }
    return result;
}