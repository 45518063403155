import styles from './EmployeePayrollSearchResultItem.module.css'
import React, {useEffect} from 'react';
import {navigate} from '@reach/router';

export default function EmployeePayrollSearchResultItem({resultItem, onResultSelected}) {

    useEffect(() => {
    },[resultItem]);

    return <div className={`${styles.SearchResultItem}`} onClick={() => {onResultSelected(); navigate(`/employeePayroll/${resultItem.employeePayrollId}`);}} >
            <div>
                <p className={`${styles.SearchResultItemName}`}>{resultItem.name}</p>
                <p className={`${styles.SearchResultItemLocation}`}>{resultItem.niNumber}</p>
            </div>
	</div>;
}