import styles from './FormSelectWithoutLabel.module.css'
import IconChevronDown from '../../../Images/IconChevronDown.svg';
import React, {useEffect} from 'react';



export default function FormSelectWithoutLabel({className, label, name, placeholder = null, required, options,slim = false, ...other}) {
	return <div className={`${className ? className : ''}`}>
		<label className={`${styles.SelectWithoutLabel_Label}`} htmlFor={name}>
		{required ? `${label} *` : label}
		</label>
		<div className={`${styles.SelectWithoutLabel_Wrapper}`}>
			<select className={`${styles.SelectWithoutLabel_Select} ${slim === true ? styles.SelectWithoutLabel_Select_Slim : ''}`} name={name} {...other}>
				{placeholder !== null && <option value="" >{placeholder}</option>}
				{options ? options.map((option) => <option value={option.value} key={option.value}>{option.text}</option>) : ''}
			</select>
			<img className={`${styles.SelectWithoutLabel_Icon}`} src={IconChevronDown} alt="Down arrow"/>
		</div>
	</div>
}
