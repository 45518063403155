import styles from './PayrollSummaryListItem.module.css'
import React, { useEffect, useState } from 'react';
import {navigate} from '@reach/router';
import FormInputCheckbox from '../App/Form/FormInputCheckbox';
import Tooltip from 'react-tooltip-lite';
import moment from 'moment';

export default function PayrollSummaryListItem({data, setShowEdit, showEdit, selectAll, checked}) {
	const [initalSet, setInitalSet] = useState(false);
	const [selected, setSelected] = useState(false);

	function openEmployeePayroll(id){
		navigate(`/employeePayroll/${id}`);
	}

	function handelOnChange(){
		setShowEdit();
		setSelected(!selected);
	}

	function numberWithCommas(x) {
		var numbers = x.toString().split('.');
		var output = (numbers[0].length>0 ? numbers[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0")+"."+(numbers[1].length>1 ? numbers[1]: "0000");
		return output;
	}

	useEffect(() => {
		if(!initalSet){
			setSelected(checked);
			setInitalSet(true)
		}
	}, []);

	useEffect(() => {
		if(initalSet){
			setSelected(selectAll)
		}
		
	}, [selectAll]);

	return <>
		{data &&
			<div className={`${styles.ItemWrapper}`}>
				<div className={`row collapse ${styles.DetailsWrapper}`}>
						<div className={`columns large-3 medium-12`}>
							{showEdit &&
								<div className={`row collapse `}>
									<div className={`columns large-2 medium-12 ${styles.CheckboxWrapper}`}>
										<FormInputCheckbox id={data.id} checked={selected} onChange={() => handelOnChange()}/>
									</div>
									<div className={`columns large-10 medium-12 ${styles.NameWrapper}`}>
										<button className={`${styles.Name}`} onClick={()=> openEmployeePayroll(data.id)} name="employeeName-btn">{data.name}</button>
										<p className={`${styles.NiNumber}`}>{data.ni}</p>
										{data.department!= null && data.department!= "" &&
											<p className={`${styles.NiNumber}`}>Department: {data.department}</p>
										}
										<p className={`${styles.NiNumber}`}>Works number: {data.worksNumber}</p>
										
									</div>
								</div>
							}
							{!showEdit &&
								<>	
									<button className={`${styles.Name}`} onClick={()=> openEmployeePayroll(data.id)} name="employeeName-btn">{data.name}</button>
									<p className={`${styles.NiNumber}`}>{data.ni}</p>
									{data.department!= null && data.department!= "" &&
										<p className={`${styles.NiNumber}`}>{data.department}</p>
									}
									<p className={`${styles.NiNumber}`}>Works number: {data.worksNumber}</p>
										
								</>
							}
						</div>
						<div className={`columns large-2 medium-12 ${styles.AdditionalWrapper}`}>
							{data.memo && data.memo != "" &&
								<Tooltip content={data.memo} className={`${styles.AdditionalItem}`}>
									<p className={`${styles.AdditionalItemText}`}>Memo</p>
								</Tooltip>
							}
							{data.smp && data.smp != "" &&
								<Tooltip content={
									<p>
										Medical evidence received: {(data.smpMedicalEvidenceReceived ? "Yes" : "No")}<br/>
										Last day of work: {(data.smpLastDayOfWork? moment(data.smpLastDayOfWork).format("DD/MM/YYYY") : "Not Set")} </p>
									} className={`${styles.AdditionalItem}`}>			
									<p className={`${styles.AdditionalItemText}`}>SMP</p>
								</Tooltip>
							}
							{data.spp && data.spp != "" &&
								<Tooltip content={
									<p>
										Declaration received: {(data.sppDeclarationReceived ? "Yes" : "No")} <br/>
										Last day of work: {(data.sppLastDayOfWork ? moment(data.sppLastDayOfWork).format("DD/MM/YYYY") : "Not Set")}</p>
									} className={`${styles.AdditionalItem}`}>			
									<p className={`${styles.AdditionalItemText}`}>SPP</p>
								</Tooltip>
							}
							{data.statutorySickPay && data.statutorySickPay.length > 0 &&
								<Tooltip content={
									<ul className={`${styles.AdditionalItemsList}`}>
										{data.statutorySickPay.map((item, i) =>
											<li key={i}>{moment(item.startDay).format("DD/MM/YYYY")} - {moment(item.lastDay).format("DD/MM/YYYY")}</li>)
										}
									</ul>
									} className={`${styles.AdditionalItem}`}>			
									<p className={`${styles.AdditionalItemText}`}>SSP</p>
								</Tooltip>
							}
							{data.documentFileNames && data.documentFileNames.length > 0 &&
								<Tooltip content={
									<ul className={`${styles.AdditionalItemsList}`}>
										{data.documentFileNames.map((doc, i) => 
											<li key={i}>{doc}</li>)}
									</ul>
								} className={`${styles.AdditionalItem}`}>			
									<p className={`${styles.AdditionalItemText}`}>Documents</p>
								</Tooltip>
							}
						</div>
						<div className={`columns large-4 medium-12 end `}>
						{data.paymentDeductions &&
								data.paymentDeductions.map((item, i) =>
									<div key={i}  className={`row ${styles.PaymentDeductionWrapper}`}>
										<p className={`columns large-6 medium-4 small-6 ${styles.PaymentDeductionCol}`}>{item.payment === true? <span className={`${styles.Plus}`}>+</span> : <span className={`${styles.Negative}`}>-</span>}{item.title}</p>
										<p className={`columns large-1 medium-1 small-2 `}>{item.qty}</p>
										<p className={`columns large-5 medium-4 small-4 end  ${styles.PaymentDeductionAmount}`}>£{numberWithCommas(item.amount)}</p>
									</div>
								)
							}
						</div>
					</div>
				<div className={`row ${styles.TotalWrapper} collapse`}>
					<div className={`columns large-8 medium-12`}></div>
					<div className={`columns large-4 medium-12`}>
						<p><b>Total payments:</b> £{numberWithCommas(data.totalPayments)} <span className={`${styles.TotalsPlus}`}>+</span></p>
						<p><b>Total deductions:</b> £{numberWithCommas(data.totalDeductions)} <span className={`${styles.TotalsNegative}`}>-</span></p>
					</div>
				</div>
			</div>
		}
	</>
}
