import styles from './HeaderLoggedIn.module.css'
import Logo from '../../../Images/Logo.svg';
import React, {useEffect, useState} from 'react';
import HeaderDropdownMenu from './HeaderDropdownMenu';
import {navigate} from '@reach/router';
import Avatar from "../../../Images/Avatar.svg";
import { useUser } from '../../../context/UserProvider';

export default function HeaderLoggedIn({className,auth}) {
	const {user, getClientCompanyName} = useUser();
	const [name, setName] = useState('');


	useEffect(() => {
		setName(getClientCompanyName());
	}, [user]);

	return <div className={`${styles.Header} ${className ? className : ''}`}>
		<div className={`${styles.Row} row full-width collapse`}>
			<div className={`columns small-6 `}>
				<div className={`${styles.LogoWrapperWrapper}`} onClick={()=>navigate(`/`)}>
					<img src={Logo} alt="Logo"/>
					{user &&
						<p className={`${styles.ClientCompanyName}`}>{name}</p>
					}
				</div>

				
			</div>
			<HeaderDropdownMenu auth={auth}/>
		</div>
	</div>
}
