import styles from './CompanySearch.module.css'
import React, { useEffect, useState } from 'react';
import SearchInput from './SeachInput';
import ButtonGhost from '../Button/ButtonGhost';

export default function CompanySearch({ companies, setCompany}) {
        const [searchString, setSearchString] = useState("");

        function search(searchString) {
                setSearchString(searchString);
        }

        function clearSearch() {
                setSearchString("");
        }

        function handelChangeCompany(companyId){
                clearSearch("");
                setCompany(companyId);
                document.getElementById('search-input').value = "";
        }

        return <div className={`${styles.DatabasePathSearchWrapper}`}>
                <SearchInput label="Search companies" name="search" search={search} clearSearch={clearSearch} />
                <div className={`${styles.ResultsWrapper}`}>
                        {searchString != "" && companies && companies.length > 0 &&
                                companies.filter(item => item.text.toLowerCase().includes(searchString.toLowerCase())).map((company, i) =>
                                        <div className={`${styles.DatabasePathSearchItem}`} key={i}>
                                        <p className={`${styles.DatabasePathSearchText}`}>{company.text}</p>
                                        <ButtonGhost size="thin" text="Select" onClick={()=> handelChangeCompany(company.value)}/>
                                        </div>
                                )
                        }
                </div>
        </div>
}