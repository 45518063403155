import styles from './WorkingDays.module.css'
import React, {useState, useEffect}from 'react';

export default function WorkingDays({onSelect, pattern, readOnly}) {
    const [value, setValue] = useState('NNNNNNN');

    function onClickHandler(index){
        var valueString = value.toString();
        var currentVal = valueString.slice(index, index + 1);
        var newVal = '';
        if(currentVal === 'N') newVal = 'Q';
        else if(currentVal === 'Q') newVal = 'N';

        var finalValue = `${valueString.slice(0, index)}${newVal}${valueString.slice(index + 1, valueString.length)}`;

        setValue(finalValue)
        onSelect(finalValue)
    }

    useEffect(() => {
        if(pattern) {
            setValue(pattern);
        }
    }, [pattern])

    return <div>
        <label className={`${styles.WorkingDays_Label}`} htmlFor="workingDays">
            Working Day Pattern *
        </label>
        <div className={`${styles.WorkingDays}`}>
            <button type="button" className={`${value.toString().slice(0, 1) === 'Q' ?  styles.WorkingDaysButtonSelected : styles.WorkingDaysButton}`} onClick={()=>onClickHandler(0)} disabled={readOnly}>S</button>
            <button type="button" className={`${value.toString().slice(1, 2) === 'Q' ?  styles.WorkingDaysButtonSelected : styles.WorkingDaysButton}`} onClick={()=>onClickHandler(1)} disabled={readOnly}>M</button>
            <button type="button" className={`${value.toString().slice(2, 3) === 'Q' ?  styles.WorkingDaysButtonSelected : styles.WorkingDaysButton}`} onClick={()=>onClickHandler(2)} disabled={readOnly}>T</button>
            <button type="button" className={`${value.toString().slice(3, 4) === 'Q' ?  styles.WorkingDaysButtonSelected : styles.WorkingDaysButton}`} onClick={()=>onClickHandler(3)} disabled={readOnly}>W</button>
            <button type="button" className={`${value.toString().slice(4, 5) === 'Q' ?  styles.WorkingDaysButtonSelected : styles.WorkingDaysButton}`} onClick={()=>onClickHandler(4)} disabled={readOnly}>T</button>
            <button type="button" className={`${value.toString().slice(5, 6) === 'Q' ?  styles.WorkingDaysButtonSelected : styles.WorkingDaysButton}`} onClick={()=>onClickHandler(5)} disabled={readOnly}>F</button>
            <button type="button" className={`${value.toString().slice(6, 7) === 'Q' ?  styles.WorkingDaysButtonSelected : styles.WorkingDaysButton}`} onClick={()=>onClickHandler(6)} disabled={readOnly}>S</button>
        </div>
    </div>
}
