import styles from './NewPayrollModal.module.css'
import H2 from '../../TextStyles/H2';
import FormTextarea from '../App/Form/FormTextarea';
import ButtonGhost from '../App/Button/ButtonGhost';
import React, { useEffect, useState } from 'react';
import Modal from '../../modal/Modal';
import FormSelectWithoutLabel from '../App/Form/FormSelectWithoutLabel';
import useForm from '../../hooks/useForm';

export default function NewPayrollModal({paymentDates, postPayroll, onClose,errorMessage, ...others }) {
	const [memoValue, setMemoValue] = useState('');
	const [dates, setDates] = useState();
	const { values, handleChange, handleSubmit } = useForm(onPostPayroll);
	const [error, setError] = useState(false);

	function onPostPayroll(){
		if(values.scheduleDateId == ""){
			setError('Please select a payment date.')
		}else{
			postPayroll(values.scheduleDateId);
			//onClose();
		}
	}


	useEffect(() => {
		var pOptions = []
		if(paymentDates){
			paymentDates.forEach(element => {
				pOptions.push({ text: element.displayText, value: element.id })
			});
			setDates(pOptions)
		}
		values.scheduleDateId = pOptions[0].value
			// 
	}, [paymentDates]);

	return <>
		{dates &&
			<Modal className={`${styles.ModalWindow}`} onClose={onClose} {...others}>
				<div>
					<div className={`${styles.ModalTitle}`}>
						<H2 className={`${styles.AddAMemo_H2}`}>Choose a payment date for payroll</H2>
					</div>
					<form className={`${styles.FormWrapper}`} onSubmit={handleSubmit}>
						<div className={`${styles.FormWrapper}`}>
							<FormSelectWithoutLabel label="Payment date" name="scheduleDateId" placeholder={""} onChange={handleChange} options={dates} value={values.scheduleDateId } required />
						</div>
						{errorMessage &&
							<div className={`${styles.FormWrapper}`}>
								<span className={`${styles.Error}`}>{errorMessage}</span>
							</div>
						}
						<div className={`${styles.ButtonWrapper}`}>
							<ButtonGhost className={`${styles.ButtonGhost}`} text={"Add"} type="submit" name="addPayroll"/>	
						</div>
					</form>
					
				</div>
			</Modal>
		}
	</>
}
