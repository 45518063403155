import styles from './EmployeeDetails.module.css'
import { navigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import ButtonGhost from '../../Symbols/App/Button/ButtonGhost';
import EmployeeDetailItem from '../../Symbols/Employee/Detail/EmployeeDetailItem';
import { Router } from '@reach/router';
import EmployeeBankDetailsModal from './EmployeeBankDetailsModal';
import WorkingDays from "../../Symbols/App/Working Days/WorkingDays";
import Document from "../../Symbols/App/Files/Document";
import { useHttp } from '../../context/HttpProvider';

export default function EmployeeDetails({ employeeId }) {
	const [details, setDetails] = useState();
	const { get } = useHttp();

	async function getEmployeeDetails() {
		await get(`employees/${employeeId}`)
			.then(async function (result) {
				setDetails(result.employee)
			}).catch(function () {

			});
	}

	function getFlag(){
		if(details.flag === 2){
			return "New employee"
		}else if(details.flag === 3){
			return "Leaver"
		}else if(details.onHold === true){
			return "On hold"
		}else{
			return ""
		}
	}

	useEffect(() => {
		getEmployeeDetails();
	}, []);

	return <div className={`${styles.Content}`}>
		<Router>
			<EmployeeBankDetailsModal path={`/bank-details`} onClose={() => navigate(`/employeeDetails/${employeeId}/`)} employeeId={employeeId} />
		</Router>
		{details != null &&
			<div className={`row`}>
				
				<div className={`columns large-8 large-offset-2 medium-10 medium-offset-1`}>
					<div className={`${styles.HeaderTitleWrapper}`}>
						<div className={`${styles.TitleWrapperWrapper}`}>
							<span className={`${styles.TitleWrapperWrapper_Title}`}>Employee details</span>
						</div>
						<div className={`${styles.ButtonGhostWrapperWrapper}`}>
							<ButtonGhost name="add-user" className={`${styles.ButtonGhost}`} text="Edit employee" onClick={() => navigate(`/edit-employee/${employeeId}`)} />
						</div>
					</div>
					<div className={`${styles.Details}`}>
						<EmployeeDetailItem title={`${details.title} ${details.firstName} ${details.lastName}`} value={getFlag()} pill={getFlag()!=""? true: false}/>
						{details.email && <EmployeeDetailItem title={"Email"} value={details.email} />}
						{details.address.addressLine1 && 
							<EmployeeDetailItem title={"Address"} value={`${details.address.addressLine1}<br>${details.address.addressLine2 === null ? '' : details.address.addressLine2}<br>${details.address.postalCode}`} />}
						<EmployeeDetailItem title={"Date of birth"} value={details.dateOfBirthString} />
						{details.payroll && <EmployeeDetailItem title={"Payroll reference number"} value={details.payroll} />}
						<EmployeeDetailItem title={"National insurance number"} value={details.niNumber} />
						<EmployeeDetailItem title={"National insurance category"} value={details.niCategory} />
						<EmployeeDetailItem title={"Start date"} value={details.startDateString} />

						{details.endDateString &&
							  <EmployeeDetailItem title={"End date"} value={details.endDateString} />}
						
						<EmployeeDetailItem title={"Sex"} value={details.gender === 0 ? 'Male' : 'Female'} />
						<EmployeeDetailItem title={"Marital status"} value={details.maritalStatusString} />
						<EmployeeDetailItem title={"Forms"} value={details.formString} />
						<EmployeeDetailItem title={"Tax code"} value={details.taxCode} />
						<EmployeeDetailItem title={"Gross salary for year"} value={`£${details.grossSalaryForYear}`} />
						<div className={`${styles.Item}`}>
							<WorkingDays name="workingDays" pattern={details.workingDaysPattern} readOnly={true}/>
						</div>
						<EmployeeDetailItem title={"Hours worked in the week"} value={details.hoursWorkedInWeek} />
						<EmployeeDetailItem title={"Department"} value={details.department} />
						{/* Payment date  */}
						<EmployeeDetailItem title={"Payment date"} value={details.scheduleDateString} />

						{details.documents && details.documents.length>0 &&
						<div className={`${styles.Row} row`}>
							<div className={`columns small-12 ${styles.Footer}`}>
								<div className={`${styles.FooterColumnFullWidth}`}>
									<p className={`${styles.FooterTitle}`}>Documents</p>
									{details.documents &&
									details.documents.map((document, i) =>
										<Document document={document} readOnly={true}/>
									)
									}
								</div>
							</div>
						</div>
						}
						<div className={`${styles.ButtonEditBankDetailsWrapper}`}>
							<ButtonGhost name="bank-details" className={`${styles.ButtonEditBankDetails}`} text="Edit bank details" onClick={() => navigate(`/employeeDetails/${employeeId}/bank-details`)} />
						</div>
					</div>
				</div>
			</div>
		}
	</div>
}
