import { httpGetWithoutCompanyId } from '../modules/backend';
import React, { useState, useEffect, useContext } from 'react';

const UserContext = React.createContext();

function UserProvider(props) {

    const refreshUser = async function () {
        const user = await httpGetWithoutCompanyId('client-users');
        if(user.clientCompanyId === null){
            user.isClientAdmin = true;
        }else{
            user.isClientAdmin = false;
        }
        setUser(user);

        if((user.id !== localStorage.getItem('UserId'))) {
            OverrideLocalStorage(user);
        }
    }

    function OverrideLocalStorage(refreshedUser) {
        localStorage.setItem('ClientCompanyName', refreshedUser.clientCompanyName);
        localStorage.setItem('UserId', refreshedUser.id);

        if(refreshedUser.clientCompanyId !== '' && refreshedUser.clientCompanyId !== null) {
            localStorage.setItem('ClientCompanyId', refreshedUser.clientCompanyId);
        } else {
            localStorage.setItem('ClientCompanyId', undefined);
        }
    }

    const setCompanyOnUser  = function (companyUser) {
        setUser(companyUser)
        OverrideLocalStorage(companyUser);
    }

    const [user, setUser] = useState();

    useEffect(() => {
        // localStorage.setItem('ClientCompanyName', null)
        async function sync() {
            await refreshUser();
        }

        if (props.auth.isAuthenticated()) {
            sync();
        }

    }, []);

    const getClientCompanyId = function () {
        return localStorage.getItem('ClientCompanyId')
    }

    const getClientCompanyName = function () {
        return localStorage.getItem('ClientCompanyName')
    }

    return <UserContext.Provider value={{ user: user, getClientCompanyName: getClientCompanyName, getClientCompanyId: getClientCompanyId, saveCompanyOnUser: setCompanyOnUser, triggerRefresh: refreshUser }} {...props} />
}

function useUser() {
    const context = useContext(UserContext)
    return context;
}

export { UserProvider, useUser }
