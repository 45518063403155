import styles from './EmployeePayrollModalAddSSP.module.css'
import H2 from '../../../TextStyles/H2';
import FormTextarea from '../../App/Form/FormTextarea';
import ButtonPrimary from '../../App/Button/ButtonPrimary';
import React, { useEffect, useState } from 'react';
import Modal from '../../../modal/Modal';
import DatePicker from '../../App/DatePicker/DatePicker';
import moment from 'moment';

export default function EmployeePayrollModalAddSSP({setStatutorySickPay, onClose, readOnly, ...others }) {
	const [startDay, setStartDay] = useState();
	const [lastDay, setLastDay] = useState();
	const [error, setError] = useState();
	
	function set(){
		if(validate()){
			// startDay.setHours(12);
			// lastDay.setHours(12);
			setStatutorySickPay({"startDay": startDay,"lastDay": lastDay });
			onClose();
		}
		
	}

	function validate(){
		if(startDay === undefined && lastDay === undefined){
			setError("Please add first and last days of sickness.");
			return false
		}
		if(startDay === undefined){
			setError("Please add first day of sickness.");
			return false
		}
		if(lastDay === undefined){
			setError("Please add last day of sickness.");
			return false
		}
		return true
	}

	return <Modal onClose={onClose} {...others}>
		<div>
			<div className={`${styles.ModalTitle}`}>
				{!readOnly &&
					<H2 className={`${styles.AddAMemo_H2}`}>Add statutory sick pay</H2>
				}
				{readOnly &&
					<H2 className={`${styles.AddSSP_H2}`}>Statutory sick pay</H2>
				}
			</div>
			<div className={`${styles.FormWrapper}`}>
				{readOnly &&
					<>
						<div className={`${styles.TextWrapper}`}>
							<p className={`${styles.AddSSP_P}`}>First day of sickness: {moment(startDay).format("DD/MM/YYYY")}</p>
						</div>
						<div className={`${styles.TextWrapper}`}>
							<p className={`${styles.AddSSP_P}`}>Last day of sickness: {moment(lastDay).format("DD/MM/YYYY")}</p>
						</div>
						<div className={`${styles.InputWrapper}`}>
							<p className={`${styles.AddSSP_P}`}>Additional information and documents can be found in the main page for this employee.</p>
						</div>
					</>
				}
				{!readOnly &&
					<>
						<div className={`${styles.InputWrapper}`}>
							<DatePicker label="First day of sickness" name="startDay" onChange={setStartDay} value={startDay} required/>
						</div>
						<div className={`${styles.InputWrapper}`}>
							<DatePicker label="Last day of sickness" name="lastDay" onChange={setLastDay} value={lastDay} required />
						</div>
						<div className={`${styles.InputWrapper}`}>
							<p className={`${styles.AddSSP_P}`}>Please add any additional information into the memo and documents section of the main page for this employee.</p>
						</div>
					</>
				}
			</div>
			{error &&
				<div className={`${styles.InputWrapper}`}>
					<span className={`${styles.Error}`}>{error}</span>
				</div>
			}
			<div className={`${styles.ButtonWrapper}`}>
				{!readOnly &&
					<ButtonPrimary name="save" className={`${styles.ButtonGhost}`} text="Add" onClick={set}/>
				}
			</div>
		</div>
	</Modal>
}
