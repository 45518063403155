import styles from './DashboardModalChooseCompany.module.css'
import H2 from '../../TextStyles/H2';
import useForm from '../../hooks/useForm';
import FormSelectWithoutLabel from '../../Symbols/App/Form/FormSelectWithoutLabel';
import FormInputText from '../../Symbols/App/Form/FormInputText';
import ButtonGhost from '../../Symbols/App/Button/ButtonGhost';
import React, {useState, useEffect} from 'react';
import { useUser } from '../../context/UserProvider';
import { navigate } from '@reach/router';
import { useHttp } from '../../context/HttpProvider';
import CompanySearch from '../../Symbols/App/Search/CompanySearch'
import {httpGetWithoutCompanyId} from "../../modules/backend";

export default function DashboardModalChooseCompany() {
	const [company, setCompany] = useState('');
	const { values, handleChange, handleSubmit } = useForm(onFormSubmit);
	const { user, saveCompanyOnUser } = useUser();
	const [companyList, setCompanyList] = useState();
	const { get } = useHttp();

	function handleChangeSetCompany(event){
		setCompany(event.target.value)
	}
	function getCompanyById(id){
		var result = companyList.filter(comp => comp.value === id);
		return result[0];
	}

	function getCompanyTextById(id){
		var result = companyList.filter(comp => comp.value === id);
		return result[0].text;
	}

	async function onFormSubmit() {
		var comp = getCompanyById(company);

		var cloneUser = {
			"accountancyId": user.accountancyId,
			"clientCompanyId": comp.value,
			"clientCompanyName": comp.text,
			"email": user.email,
			"firstName":user.firstName,
			"id":user.id,
			"lastName":user.lastName,
			"isClientAdmin": user.isClientAdmin
		};

		saveCompanyOnUser(cloneUser);
		navigate('/')
	}

	async function getCompanies() {
		await httpGetWithoutCompanyId(`client-companies/by-accountancy-id?AccountancyId=${user.accountancyId}`)
			.then(async function (result) {
				var companies = [];
				result.companies.forEach(company => {
					companies.push({
						text:company.name,
						value:company.id
					})
				});
				setCompanyList(companies)
			}).catch(function () {

				
			});
	}

	useEffect(() => {
		getCompanies()
	}, []);

	return <div className={`${styles.ModalContent}`}>
		<H2 className={`${styles.AddANewEmployee_H2}`}>
			Choose a company
		</H2>
		<form className={`${styles.FormWrapper}`} onSubmit={handleSubmit}>
			{companyList && companyList.length > 0 &&
				<CompanySearch companies={companyList} setCompany={setCompany}/>
			}
			{company !== "" &&
				<p className={`${styles.Display_Text}`}><b>Chosen company:</b> {getCompanyTextById(company)}</p>
			}
			<div className={`${styles.ButtonWrapper}`}>
				<ButtonGhost className={`${styles.GhostButton}`} text="Save" type="submit" disabled={company === ''}/>
			</div>
		</form>
	</div>
}
