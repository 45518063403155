import styles from './PaymentDeduction.module.css'
import React, {useEffect, useState}  from 'react';
import PaymentDeductionFormInput from './PaymentDeductionFormInput';
import IconDelete from '../App/Icon/IconDelete';

export default function DeductionElement({deductionElement, canDelete,  onValueChangeHandler, onRateChangeHandler, onDeleteHandler, readOnly}) {
    const [rateReadOnly, setRateReadOnly] = useState(false);
    const [valueReadOnly, setValueReadOnly] = useState(false);

    function onValueChange(event){
        onValueChangeHandler(deductionElement.id, event.currentTarget.value)
    }

    function onRateChange(event){
        onRateChangeHandler(deductionElement.id, event.currentTarget.value)
    }

    useEffect(() => {
        const quantityInput = document.getElementById(getQuantityElementId(deductionElement.id));
        quantityInput.focus();
    },[readOnly]);

    const getQuantityElementId = (id) => `quantity-${id}`;

    useEffect(() => {
        setRateReadOnly(readOnly)
        setValueReadOnly(readOnly)
        //The status will be one of 3 values and is an Enum: Variable = 0, Fixed = 1, Global = 2
		if(deductionElement.deductionType.status == 1 ){
            //fixed rate is read only
			setRateReadOnly(true)
        }
        if(deductionElement.deductionType.status == 2 ){
            //global both fields are read only
            setRateReadOnly(true)
            setValueReadOnly(true)
		}
    }, [readOnly]);
    
    return <div className={`row collapse ${styles.PaymentDeductionWrapper}`}>
        <div className={`columns small-6 medium-8 text-right`}>
            <div className={`${styles.PaymentDeductionLabelWrapper}`}>
                <span className={`${styles.PaymentDeduction_Label}`}>{deductionElement.deductionType.name}</span>
            </div>
        </div>
        <div className={`columns small-3 medium-2 ${styles.PaymentDeductionValueWrapper}`}>
            <PaymentDeductionFormInput className={`${styles.PaymentDeduction_Value}`} name={deductionElement.name} value={deductionElement.value} onChange={onValueChange}  disabled={valueReadOnly} id={getQuantityElementId(deductionElement.id)}/>
        </div>
        <div className={`columns small-3 medium-2 ${styles.PaymentDeductionValueWrapper}`}>
            <PaymentDeductionFormInput className={`${styles.PaymentDeduction_Value}`} value={deductionElement.rate} onChange={onRateChange} disabled={rateReadOnly}/>
            {canDelete && !readOnly &&
                <div onClick={()=> onDeleteHandler(deductionElement.id)}>
                <IconDelete className={`${styles.IconDelete}`}/>
                </div>
            }
        </div>
    </div>
}