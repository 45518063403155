import styles from './EmployeePayrollModalAddMemo.module.css'
import H2 from '../../../TextStyles/H2';
import FormTextarea from '../../App/Form/FormTextarea';
import ButtonPrimary from '../../App/Button/ButtonPrimary';
import React, { useEffect, useState } from 'react';
import Modal from '../../../modal/Modal';

export default function EmployeePayrollModalAddMemo({memo, setMemo,onClose, readOnly, ...others }) {
	const [memoValue, setMemoValue] = useState('');

	function set(){
		setMemo(memoValue)
		onClose();
	}

	// function clearMemo(){
	// 	setMemo('');
	// 	onClose();
	// }

	function setValue(event){
		setMemoValue(event.currentTarget.value)
	}

	useEffect(() => {
		setMemoValue(memo)
	}, []);

	return <Modal onClose={onClose} {...others}>
		<div>
			<div className={`${styles.ModalTitle}`}>
			{!readOnly &&
				<H2 className={`${styles.AddAMemo_H2}`}>{memo === ""? "Add a" : "Update"} memo</H2>
			}
			{readOnly &&
				<H2 className={`${styles.AddAMemo_H2}`}>Memo</H2>
			}
			</div>
			<div className={`${styles.FormWrapper}`}>
				<FormTextarea label="Message" name="message" value={memoValue} onChange={setValue} rows="12" disabled={readOnly}/>
			</div>
			<div className={`${styles.ButtonWrapper}`}>
				{/* {memo !== "" &&
					<ButtonGhost className={`${styles.ButtonGhost} ${styles.ButtonDelete} `} text="Delete memo" onClick={clearMemo}/>
				} */}
				{!readOnly &&
					<ButtonPrimary className={`${styles.ButtonGhost}`} text={memo === ""? "Add memo" : "Update memo"} onClick={set}/>
				}
				
				
			</div>
		</div>
	</Modal>
}
