import styles from './DashboardTabs.module.css'
import React from 'react';
import {navigate} from '@reach/router';

export default function DashboardTabs({selectedTab, setSelectedTab}) {
	return <div className={`${styles.Row} row collapse`}>
		<div className={`columns small-12`}>
			<div className={`${styles.EmployeesWrapperWrapper}`}>
				<button id="employee-tab" className={`${styles.EmployeesWrapperWrapper_Employees} ${selectedTab === 0 ? styles.Selected : ''}`} onClick={() =>  navigate("/")}>Employees</button>
				<button	id="payroll-tab" className={`${styles.EmployeesWrapperWrapper_Payroll} ${selectedTab === 1 ? styles.Selected : ''}`} onClick={() => navigate("/payroll")}>Payroll</button>
			</div>
		</div>
	</div>
}
