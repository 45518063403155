export const NiCategories = [
    {
        text: "A",
        value: "A"
    },
    {
        text: "B",
        value: "B"
    },
    {
        text: "C",
        value: "C"
    },
    {
        text: "J",
        value: "J"
    },
    {
        text: "H",
        value: "H"
    },
    {
        text: "M",
        value: "M"
    },
    {
        text: "X",
        value: "X"
    },
    {
        text: "Z",
        value: "Z"
    }
]

export const EmployeeFlags = [
    {
        text: "N/A",
        value: 1
    },
    {
        text: "New employee",
        value: 2
    },
    {
        text: "Leaver",
        value: 3
    }
]

export const Sex = [
    {
        text: "Male",
        value: 0
    },
    {
        text: "Female",
        value: 1
    }
]

export const MaritalStatus = [
    {
        text: "Single",
        value: 0
    },
    {
        text: "Married",
        value: 1
    },
    {
        text: "Divorced",
        value: 2
    },
    {
        text: "Widowed",
        value: 3
    },
    {
        text: "Civil Partnership",
        value: 4
    }
]

export const Form = [
    {
        text: "P45",
        value: 0
    },
    {
        text: "P46",
        value: 1
    }
]

export const AccountType = [
    {
        text: "Bank Account",
        value: 0
    },
    {
        text: "Building Society Account",
        value: 1
    }
]
