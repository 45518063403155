import styles from './NavigationDropdown.module.css';
import React from 'react';
import { navigate } from '@reach/router';

export default function NavigationDropDown({className,auth,isClientAdmin,onClose}) {

	const onLogoutClick = async function() {
        await auth.logout();
	};
	
	function goToDashboard(){
		navigate('/');
		onClose()
	}

	function goToChooseCompany(){
		navigate('/ChooseCompany');
		onClose()
	}

	// function goToSettings(){
	// 	navigate('/settings');
	// 	onClose()
	// }

	return <div className={`${styles.Navigation} ${className ? className : ''}`}>
		<span className={`${styles.Navigation_Dashboard}`} onClick={goToDashboard}>
			Dashboard
		</span>
		{isClientAdmin === true &&
			<span className={`settings-btn ${styles.Navigation_AdminSettings}`} onClick={goToChooseCompany}>
				Choose company
			</span>
		}
		
		<span className={`${styles.Navigation_Logout}`} onClick={onLogoutClick}>
			Log out
		</span>
	</div>
}
