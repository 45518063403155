import React from 'react'
import styles from './ReinstateEmployeeWarningModal.module.css'
import Modal from '../../modal/Modal'
import H2 from '../../TextStyles/H2';
import P from '../../TextStyles/P';
import ButtonPrimary from '../../Symbols/App/Button/ButtonPrimary'
import ButtonGhost from '../../Symbols/App/Button/ButtonGhost'
import {navigate} from '@reach/router'

export default function ReinstateEmployeeWarningModal({employeeId, onConfirm}) {

    const previousUrl = `/edit-employee/${employeeId}/`;

    function handleConfimClicked() {
        onConfirm();
        navigate(previousUrl);
    }

    return <Modal onClose={() => navigate(previousUrl)}>
        <H2>Reinstate Employee Warning</H2>
        <P>Performing this action will reinstate the employee into the payroll system.</P>
        <P>Are you sure you would like to do this?</P>
        <div className={`${styles.ButtonWrapper}`}>
            <ButtonGhost text='No' onClick={() => navigate(previousUrl)}/>
            <ButtonPrimary text='Yes' className={`${styles.PrimaryButton}`} onClick={()=> handleConfimClicked()} />
        </div>
    </Modal>
}