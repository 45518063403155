import styles from './FormInputCheckbox.module.css'
import React from 'react';

export default function FormInputCheckbox({labelClassName, className, id, name, label, ...other}) {
	return <div className={`${styles.Checkbox_Wrapper} ${className ? className : ''}`}>
		<input className={`${styles.Checkbox_Input}`} type="checkbox" name={name} id={id} {...other}/>
		<span className={`${styles.Checkbox_Input_Custom}`}/>
		<label className={`${styles.Checkbox_Label} ${labelClassName}`} htmlFor={id}>
			{label}
		</label>
	</div>
}
