import styles from './Document.module.css'
import React from 'react';
import IconDocument from '../Icon/IconDocument';

export default function Document({ document, onDelete, readOnly }) {
	function onOpenFile(url){
        window.open(url, "_blank")
    }
	return <div className={`${styles.Document}`}>
		{!document.azureBlobUrl &&
			<p className={`${styles.DocumentText}`}>{document.fileName}</p>
		}
		{document.azureBlobUrl &&
			<p className={`${styles.DocumentText}`} onClick={()=> onOpenFile(document.azureBlobUrl)}>{document.fileName}</p>
		}
		{!readOnly &&
			<IconDocument className={`${styles.DocumentDelete}`} onClick={() => onDelete(document)} />
		}
		
	</div>
}
