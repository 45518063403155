export function ValidateNiNumber(niNumber){
    //correct format LLNNNNNNL
    var regex = /^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-DFM]{0,1}$/;
    var result = regex.test(niNumber);
    return result
}
export function NotEmptyString(value){
    return value!= "";
}

export function ValidateBankAccountNumber(numbers){
    //Account number is 8 digits
    var regex = /^[0-9]{8}$/;
    var result = regex.test(numbers);
    return result
}

export function ValidateBankSortcode(numbers){
    //Sortcode is 6 digits in groups of 2
    var regex = /^[0-9]{2}$/;
    var result = regex.test(numbers);
    return result
}

export function ValidateDateOfBirth(date){
    //older than 16
    var dateToCheckAgainst = new Date();
    dateToCheckAgainst.setFullYear( dateToCheckAgainst.getFullYear() - 16 );
    var result = date <= dateToCheckAgainst;
    return result;
}

export function ValidatePostcode(postcode){
    var result = (postcode.length > 0 && postcode.length < 9);
    return result;
}

export function ValidateEmail(email){
    var regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    var result = regex.test(email);
    return result
}

export function ValidateInt(int){
    return int < 2147483648;
}

export function ValidateTaxCode(taxCode) {
    var regex = /[SK]?\d{2,4}[LH]?$/g;
    var result = regex.test(taxCode);
    return result;
}

export function ValidateNotNullLengthLessThanOrEqual30(value){
    var result = (value!= "" && value.length <= 30)
    return result;
}

export function ValidateLengthLessThanOrEqual30(value){
    var result = (value == "" || value.length <= 30)
    return result;
}

export function ValidateNotNullLengthLessThanOrEqual10(value){
    var result = (value!= "" && value.length <= 10)
    return result;
}

export function ValidateLengthLessThanOrEqual2(value){
    var result = (value == "" || value.length <= 2)
    return result;
}
