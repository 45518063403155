import styles from './EmployeeDetailItem.module.css'
import React from 'react';

export default function EmployeeDetailItem({ title, value, pill }) {
    
	return <div className={`${styles.EmployeeDetailItem}`}>
        <p className={`${styles.EmployeeDetailItem_Title}`}>{title}</p>
        {value &&
            <p className={`${styles.EmployeeDetailItem_Value} ${pill === true?styles.EmployeeDetailItem_Pill : ''}`}  dangerouslySetInnerHTML={{__html: value}}></p>
        }
	</div>
}
