import styles from './EmployeePayrollModalAddSMP.module.css'
import H2 from '../../../TextStyles/H2';
import FormTextarea from '../../App/Form/FormTextarea';
import ButtonPrimary from '../../App/Button/ButtonPrimary';
import React, { useEffect, useState } from 'react';
import Modal from '../../../modal/Modal';
import DatePicker from '../../App/DatePicker/DatePicker';
import moment from 'moment';
import FormInputCheckbox from '../../App/Form/FormInputCheckbox'

export default function EmployeePayrollModalAddSPP({statutoryPaternityPay,setstatutoryPaternityPay, onClose, readOnly, ...others }) {
	const [expectedDateOfBirth, setExpectedDateOfBirth] = useState();
	const [lastDayOfWork, setLastDayOfWork] = useState();
	const [declarationReceived, setDeclarationReceived] = useState(false);
	const [error, setError] = useState();
	const MinDateString = '0001-01-01T00:00:00';
	
	function set(){
		if(validate()){
			setstatutoryPaternityPay({"expectedDateOfBirth": expectedDateOfBirth,"lastDayOfWork": lastDayOfWork, "declarationReceived": declarationReceived });
			onClose();
		}
		
	}

	function validate(){
		if(expectedDateOfBirth === undefined && lastDayOfWork === undefined){
			setError("Please add expected date of birth and last days of work.");
			return false
		}
		if(expectedDateOfBirth === undefined){
			setError("Please add expected date of birth.");
			return false
		}
		return true
	}

	function handelDeclarationReceived(event){
		setDeclarationReceived(event.target.checked)
	}

	useEffect(() => {
		if(statutoryPaternityPay){
			setExpectedDateOfBirth(statutoryPaternityPay.expectedDateOfBirth)
			setLastDayOfWork(statutoryPaternityPay.lastDayOfWork)
			setDeclarationReceived(statutoryPaternityPay.declarationReceived)
		}
	}, []);

	return <Modal onClose={onClose} {...others}>
		<div>
			<div className={`${styles.ModalTitle}`}>
				{!readOnly &&
					<H2 className={`${styles.AddAMemo_H2}`}>{statutoryPaternityPay === null? "Add " : "Update"} statutory paternity pay</H2>
				}
				{readOnly &&
					<H2 className={`${styles.AddSSP_H2}`}>Statutory paternity pay</H2>
				}
			</div>
			<div className={`${styles.FormWrapper}`}>
				{readOnly &&
					<>
						<div className={`${styles.TextWrapper}`}>
							<FormInputCheckbox label="Declaration received" name="declarationReceived" value={declarationReceived} checked={declarationReceived} disabled/>
						</div>
						<div className={`${styles.TextWrapper}`}>
							<p className={`${styles.AddSSP_P}`}>Expected date of birth: {moment(expectedDateOfBirth).format("DD/MM/YYYY")}</p>
						</div>
						<div className={`${styles.TextWrapper}`}>
							<p className={`${styles.AddSSP_P}`}>Last day of work: {moment(lastDayOfWork).format("DD/MM/YYYY")}</p>
						</div>
						<div className={`${styles.InputWrapper}`}>
							<p className={`${styles.AddSSP_P}`}>Additional information and documents can be found in the main page for this employee.</p>
						</div>
					</>
				}
				{!readOnly &&
					<>
						<div className={`${styles.CheckboxWrapper}`}>
							<FormInputCheckbox label="Declaration received" name="declarationReceived" onChange={handelDeclarationReceived} value={declarationReceived} checked={declarationReceived} required disabled={statutoryPaternityPay?.declarationReceived != null}/>
						</div>
						<div className={`${styles.InputWrapper}`}>
							<DatePicker label="Expected date of birth" name="expectedDateOfBirth" onChange={setExpectedDateOfBirth} value={expectedDateOfBirth} required disabled={statutoryPaternityPay?.expectedDateOfBirth != null}/>
						</div>
						<div className={`${styles.InputWrapper}`}>
							<DatePicker label="Last day of work" name="lastDay" onChange={setLastDayOfWork} value={lastDayOfWork} disabled={statutoryPaternityPay?.lastDayOfWork != null && statutoryPaternityPay?.lastDayOfWork != MinDateString}/>
						</div>
						<div className={`${styles.InputWrapper}`}>
							<p className={`${styles.AddSSP_P}`}>Please add any additional information into the memo and documents section of the main page for this employee.</p>
						</div>
					</>
				}
			</div>
			{error &&
				<div className={`${styles.InputWrapper}`}>
					<span className={`${styles.Error}`}>{error}</span>
				</div>
			}
			<div className={`${styles.ButtonWrapper}`}>
				{!readOnly &&
					<ButtonPrimary name="save" className={`${styles.ButtonGhost}`} text={statutoryPaternityPay === null? "Add" : "Update"} onClick={set}/>
				}
			</div>
		</div>
	</Modal>
}
