import styles from './DashboardPayroll.module.css'
import PayrollItem from '../App/Grid/SevenColumnRow';
import { navigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import ButtonGhost from '../App/Button/ButtonGhost';
import ButtonPrimary from '../App/Button/ButtonPrimary';
import { useUser } from '../../context/UserProvider';
import InfiniteScroll from '../App/Scroll/InfiniteScroll'
import PayrollStatusType from '../../domain/payrollStatusType';
import NewPayrollModal from '../Payroll/NewPayrollModal';
import LoadingModal from '../App/Loading/LoadingModal';
import { useHttp } from '../../context/HttpProvider';


export default function DashboardPayroll() {
	const { user } = useUser();
	const [payrollSubmissions, setPayrollSubmissions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [error, setError] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [hasPendingPayrollSubmissions, setHasPendingPayrollSubmissions] = useState(false);
	const [paymentDates, setPaymentDates] = useState();
	const [showModal, setShowModal] = useState(false);
	const [showLoadingModal, setShowLoadingModal] = useState(false);
	const { get, post, put } = useHttp();

	// This is to prevent the button visibly changing while the page is loading
	const [apiCallComplete, setApiCallComplete] = useState(false)

	async function getPayrollSubmissions(refreshList = false) {
		setLoading(true);
		var skip = payrollSubmissions.length;
		var take = 10;
		if (refreshList) {
			skip = 0;
			take = payrollSubmissions.length;
		}
		await get(`payroll-submissions/by-company-id?Skip=${skip}&Take=${take}`)
			.then(async function (result) {
				//Order is end date desc from backend, so put the newly loaded payroll submissions first
				const allPayrollSubmissions = refreshList ? result.payrollSubmissions : [...result.payrollSubmissions, ...payrollSubmissions];
				setPayrollSubmissions(allPayrollSubmissions);
				setApiCallComplete(true);
				setPaymentDates(result.availableScheduleDatesForNewPayroll)

				if (result.payrollSubmissions.length === 0) {
					setHasMore(false);
				}
				setLoading(false);
			}).catch(function (error) {
				setIsError(true);
				setLoading(false);
			});
	}
	

	useEffect(() => {
		getPayrollSubmissions();
	}, []);

	function anyPendingPayrollSubmissions(allPayrollSubmissions) {
		return allPayrollSubmissions.some(submission => submission.status === PayrollStatusType.Pending)
	}

	async function newPayroll() {
		setError("")
		setShowModal(true)
	}
	function postFromModal(scheduleDateId){
		//show loading modal
		setShowLoadingModal(true)
		postNewPayroll(scheduleDateId)
	}

	async function postNewPayroll(scheduleDateId){
		await post('payroll-submissions', { scheduleDateId: scheduleDateId })
				.then(async function (response) {
					setShowModal(false)
					setLoading(false);
					setShowLoadingModal(false);
					navigate(`/payrollSummary/${response.data.payrollSubmissionId}/true`);
				})
				.catch(function (error) {
					setShowLoadingModal(false);
					setErrorFromResponse(error);
				});
	}



	function setErrorFromResponse(error) {
		setIsError(true);
		setError(error.response.data);
		setLoading(false);
	}

	return <div className={`${styles.Content}`}>
		<div>
			{showLoadingModal &&
			<LoadingModal />
			}			
			<div className={`row`}>
				<div className={`columns small-12`}>
					{showModal &&
						<NewPayrollModal paymentDates={paymentDates} onClose={()=>setShowModal(false)} postPayroll={postFromModal} errorMessage={error}/>
					}

					<div className={`${styles.ButtonPrimaryDropdownWrapper}`}>
						{paymentDates && paymentDates.length>0 &&
							<ButtonGhost text='New payroll' onClick={newPayroll} name="newPayroll" />
						}
					</div>
					<div>
						<div className={`${styles.SearchResultsHeader} ${styles.Row_FlexWrapper}`}>
							<div className={`${styles.PayPeriodWrapperWrapper_FlexWrapper}`}>
								<div className={`${styles.PayPeriodWrapperWrapper}`}>
									<span className={`${styles.PayPeriodWrapperWrapper_PayPeriod}`}>Pay period</span>
								</div>
							</div>
							<div className={`${styles.PaymentDateWrapperWrapper_FlexWrapper}`}>
								<div className={`${styles.PaymentDateWrapperWrapper}`}>
									<span className={`${styles.PaymentDateWrapperWrapper_PaymentDate}`}>Payment date</span>
								</div>
							</div>
							<div className={`${styles.StatusWrapperWrapper_FlexWrapper}`}>
								<div className={`${styles.StatusWrapperWrapper}`}>
									<span className={`${styles.StatusWrapperWrapper_Status}`}>
										Status
									</span>
								</div>
							</div>
						</div>

						<div id="payrollSubmitions-wrapper">
							{payrollSubmissions &&
								payrollSubmissions.map((payroll, i) =>
									<PayrollItem key={i} className={`${styles.ResultsRow}`} payroll={payroll} />
								)
							}
						</div>
					</div>
					<InfiniteScroll loadFunction={getPayrollSubmissions} error={isError} isLoading={loading} hasMore={hasMore} />
				</div>
			</div>
		</div>
	</div>
}
